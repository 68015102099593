import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type IOption = {
  value: string,
  label: {
    fr: string,
    en: string,
  },
};

export type IOptionTranslated = {
  value: string,
  label: string,
};

const useOptionsLang = (options: IOption[], defaultLang = 'fr'): IOptionTranslated[] => {
  const [params] = useSearchParams();
  const lang = (params.get('lang') || defaultLang) as 'fr' | 'en';

  const translatedOptions = useMemo(() => (
    options.map((option) => ({ label: option.label[lang], value: option.value }))
  ), [params, options]);

  return translatedOptions;
};

export default useOptionsLang;
