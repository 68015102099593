import React, { useEffect, useState } from 'react';
import { useController, Control } from 'react-hook-form';
import ReactQuill from 'react-quill';
import './quill.bubble.css';
import styles from './text-editor.module.scss';

type TextEditorProps = {
  label?: string | null,
  placeholder?: string,
  name: string;
  control: Control;
  defaultValue?: string;
  rules: any,
  maxlength?: number | null,
  discret?: boolean,
};

const modules = {
  toolbar: [
    // [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [
      'link',
      // 'image',
    ],
    ['clean'],
  ],
};

const formats = [
  // 'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link',
  'align',
  // 'image',
];

const TextEditor = ({
  label = '',
  placeholder = '',
  name,
  control,
  rules = {},
  defaultValue = '',
  maxlength,
  discret = false,
}: TextEditorProps) => {
  const [length, setLength] = useState(0);
  const { field: { onChange, value } } = useController({
    name, control, defaultValue, rules,
  });

  useEffect(() => {
    if (!defaultValue || value) return;
    onChange(defaultValue);
  }, [value, defaultValue]);

  const handleChange = (content: any, editor: any) => {
    setLength(editor.getLength() - 1);
    if (maxlength && (editor.getLength() - 1) > maxlength) return;
    onChange(content);
  };

  return (
    <div className={styles.field}>
      {label && <label>{label}</label>}
      <div id="quill" className={`${styles.texteditor}${discret ? ` discret ${styles.discret}` : ''}`}>
        <ReactQuill
          theme="bubble"
          placeholder={placeholder}
          bounds='#quill'
          value={value}
          modules={modules}
          formats={formats}
          onChange={(content: any, delta: any, source: any, editor: any) => {
            handleChange(content, editor);
          }}
        />
      </div>
      {(maxlength)
        && (
        <p className={styles['count-characters']}>
          Caractères <span>{`${length} / ${maxlength}`}</span>
        </p>
        )
      }
    </div>
  );
};

export default TextEditor;
