import React from 'react';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useGetTags } from '../../../hooks/tags';
import InputSearch from '../../../components/InputSearch';
import styles from './list.module.scss';
import CreateTag from '../../../components/form/CreateTag';

const Profiles = () => {
  const { t, i18n } = useTranslation('tags');
  const lang = i18n.language;

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search') || '';
  const debouncedSearch = useDebounce<string>(search, 500);
  const { data: tags = [], isLoading } = useGetTags(
    { search: debouncedSearch },
  );

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };
  console.log(tags);
  return (
    <div className={styles.listTags}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          <span>{tags.length}</span>
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            defaultValue={search}
          />
        </div>
      </div>
      <div className={styles.row}>
        <CreateTag />
      </div>
      {isLoading && <p>Loading</p>}
      {tags.length > 0 && (
        <div className={styles.tags}>
          {tags.map((tag: any) => (
            <NavLink
              to={`/tags/edit/${tag._id}`}
              style={{ backgroundColor: tag.color }}
              className={styles.tag}
              key={`tag-${tag._id}`}>
              <p>{tag.name[lang]}</p>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default Profiles;
