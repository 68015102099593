import apiSlice from '..';
import { IUser } from '../../../types/users';

export interface CredentialsRequest {
  email: string
  password: string
}

export interface forgotPasswordRequest {
  email: string
}

export interface resetPasswordRequest {
  token: string
  password: string
}

export interface UserResponse {
  user: IUser
  token: string
}

const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, CredentialsRequest>({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
      transformResponse: (response: any) => {
        localStorage.setItem('token', response.token);
        return response;
      },
    }),
    forgotPassword: builder.mutation<any, forgotPasswordRequest>({
      query: (data) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: any) => {
        console.log('forgot-password', response);
        return response;
      },
    }),
    resetPassword: builder.mutation<any, resetPasswordRequest>({
      query: (data) => {
        const { token, ...body } = data;
        return ({
          url: `/reset-password/${token}`,
          method: 'POST',
          body,
        });
      },
      transformResponse: (response: any) => {
        console.log('forgot-password', response);
        return response;
      },
    }),
    getProfile: builder.mutation<UserResponse, void>({
      query: () => '/users/profile',
      transformResponse: (response: any) => ({
        user: { ...response },
        token: localStorage.getItem('token') || '',
      }),
    }),
    updateProfile: builder.mutation<any, Partial<IUser>>({
      query: (data) => ({
        url: '/users/profile',
        method: 'PUT',
        body: data,
      }),
      transformResponse: (response: any) => response?.user,
    }),
  }),
});

const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetProfileMutation,
  useUpdateProfileMutation,
} = authApi;

export {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetProfileMutation,
  useUpdateProfileMutation,
};
