import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { Provider } from 'react-redux';
import {
  QueryClient,
  QueryClientProvider,
  MutationCache,
  QueryCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import store from './stores';
import './styles/App.scss';

import AppRoutes from './AppRoutes';
import './i18n/config';
import Mobile from './pages/mobile-page';

function App() {
  const { width } = useWindowSize();
  const handleErrorRequest = (error: any) => {
    const url = error?.config?.url;
    const statusCode = error?.response?.status;
    console.log('Request url', url);
    console.log('Request statusCode', statusCode);

    if (statusCode === 401) {
      localStorage.removeItem('token');
    }

    if (statusCode === 401 && !window.location.href.includes('/login')) {
      window.location.href = '/login';
    }
  };
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 30000,
        retry: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error: any) => {
        // error
        handleErrorRequest(error);
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => { // (error, _variables, _context, mutation)
        // if (mutation.options.onError) return;
        // console.log(error, _variables, _context, mutation);
        handleErrorRequest(error);
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {width > 1024
          ? <Router>
            <AppRoutes />
          </Router>
          : <Mobile />
          }
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
