import * as React from 'react';

const IconQuiz = ({ fill = '#949494', ...props } : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M6.5 0a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13Zm0 9.1a.65.65 0 1 0 0 1.3.65.65 0 0 0 0-1.3Zm0-6.175a2.356 2.356 0 0 0-2.356 2.356.65.65 0 1 0 1.3 0 1.056 1.056 0 1 1 1.45.982c-.44.175-1.044.625-1.044 1.375V7.8a.65.65 0 1 0 1.3 0c0-.159.032-.238.17-.305l.056-.027A2.357 2.357 0 0 0 6.5 2.925Z"
    />
  </svg>
);

export default IconQuiz;
