import React, { useRef } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import styles from './search-plant.module.scss';
import Modal from '../../../lib/Modal';
import SelectPlant from './SelectPlant';
import Loader from '../../Loader';
// import Loader from '../../Loader';

type SearchPlantsProps = {
  onSubmit?: (plantId: number) => void,
  isLoading: boolean,
};

const SearchPlants = ({
  onSubmit,
  isLoading,
} : SearchPlantsProps) => {
  const { t } = useTranslation(['createQuiz']);
  const modalPlants = useRef<any>();
  const selectPlantsRef = useRef<any>();

  const addRecognitionPlants = () => {
    const plant = selectPlantsRef.current.getPlant();
    if (plant.value && onSubmit) onSubmit(plant.value);
  };

  return (
    <>
      <button className={`secondary ${styles.btn}`} onClick={() => modalPlants.current.open()}>
        {t('preview.choosePlant')}
        <span>
          <IoChevronDownOutline />
        </span>
      </button>
      <Modal ref={modalPlants}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <h5>{t('preview.choosePlant', { ns: 'createQuiz' })}</h5>
            <p onClick={() => modalPlants.current.close()}>Fermer X</p>
          </div>
          <SelectPlant ref={selectPlantsRef} defaultPlant={null} />
          <div className={styles.containerBtn}>
            {isLoading && (
              <div className={styles.loader}>
                <Loader small />
              </div>
            )}
            <button disabled={isLoading} onClick={() => addRecognitionPlants()}>{t('validate', { ns: 'global' })}</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchPlants;
