import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import Edit from '../edit';
import styles from './create-center.module.scss';

const CreateOrganisation = () => {
  const { t } = useTranslation(['global']);
  return (
    <div className={styles.createCenter}>
      <div className={styles.header}>
        <NavLink className={styles.backward} to='/centers'>
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
      </div>
      <Edit />
    </div>
  );
};

export default CreateOrganisation;
