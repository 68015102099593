import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../stores';
import { useLoginMutation } from '../../services/api/auth';
import SignInForm from '../../components/form/SignInForm';
import { setCredentials } from '../../stores/slices/authSlice';

import ImgSignin from '../../assets/images/illu-signin.svg';

import styles from './signin.module.scss';

export interface CredentialsRequest {
  email: string
  password: string
}

const SignIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (userInfo: any) => {
    try {
      setIsError(false);
      const user = await login(userInfo).unwrap();
      dispatch(setCredentials(user));
      navigate('/users/clients');
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
  };

  return (
    <div className={styles.signin}>
      <div className={styles.container}>
        <div>
          <div className={styles.form}>
            <h1>Connexion</h1>
            <SignInForm
              submit={handleSubmit}
              errorMessage={isError ? 'Email ou mot de passe incorrect' : ''}
            />
            <Link to="/forgot-password">{t('forgot.link')}</Link>
          </div>
        </div>
        <div>
          <img src={ImgSignin} alt="illutration connexion" />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
