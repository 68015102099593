import React from 'react';
import { IoAdd } from 'react-icons/io5';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import styles from './list.module.scss';
import { useGetOrganisations, useGetOrganisationsList } from '../../../hooks/organisations';
import useOptionsLang from '../../../hooks/useOptionsLang';
import InputSearch from '../../../components/InputSearch';
import OrganisationsTable from '../../../components/OrganisationsTable';
import ButtonExpandable from '../../../components/ButtonExpandable';
import FilterSearchParam from '../../../components/FilterSearchParam';
import { IOrganisation } from '../../../types/organisations';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';

const Organisations = () => {
  const { t } = useTranslation('organisations');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const debouncedSearch = useDebounce<string>(search, 500);
  const { data: list } = useGetOrganisationsList();
  const { data: dataOrganisations = [], isLoading } = useGetOrganisations({
    ...paramsObject,
    search: debouncedSearch,
  });
  const organisations: IOrganisation[] = dataOrganisations || [];

  const organisationTypesTranslated = useOptionsLang(list?.organisationTypes || []);
  const tags = useTagsToOptionsLang(list?.tags || []);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.listOrganisations}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          {organisations.length > 0 && 's'}
          <span>{organisations.length}</span>
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            defaultValue={search}
          />
          <NavLink to="/organisations/create">
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new-organisation')}
            />
          </NavLink>
        </div>
      </div>
      {isLoading && <p>Loading</p>}
      <div className={styles.filters}>
        <FilterSearchParam
          label={t('filters.type')}
          name='type'
          options={organisationTypesTranslated}
        />
        <FilterSearchParam
          label={t('filters.region')}
          name='region'
          options={list?.organisationRegions || []}
          feminine
        />
         <FilterSearchParam
          label={t('filters.tags')}
          name='tag'
          options={tags || []}
        />
      </div>
      {organisations.length > 0 && (
        <OrganisationsTable
          organisations={organisations}
          handleSorting={handleSorting}
        />
      )}
    </div>
  );
};

export default Organisations;
