import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import { NavLink, useNavigate } from 'react-router-dom';
import FormationForm from '../../../components/form/FormationForm';
import { useCreateFormation } from '../../../hooks/formations';

import styles from './formation-create.module.scss';

const CreateFormation = () => {
  const { t } = useTranslation(['global', 'formations']);
  const navigate = useNavigate();
  // Create
  const {
    mutateAsync: createFormation,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreateFormation();

  const formRef = useRef<any>();
  const errorApi: any = errorPost;
  console.log(errorApi);

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;
    const { formation: createdFormation }: any = await createFormation(data);
    if (createdFormation) navigate('/formation');
  };
  return (
    <div className={styles['create-formation']}>
      <NavLink className={styles.backward} to="/formation">
        <FaArrowLeft />
        {t('back', { ns: 'global' })}
      </NavLink>
      <button disabled={isPostLoading} onClick={handleSubmit}
        className={styles.submit}
      >
        {isPostLoading && <div className={styles.dotLoader} />}
        <span>{t('save', { ns: 'global' })}</span>
      </button>
      <div className={styles.content}>
        <FormationForm ref={formRef} />
      </div>
    </div>
  );
};

export default CreateFormation;
