import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import { NavLink, useParams } from 'react-router-dom';
import { useGetFormation, useUpdateFormation } from '../../../hooks/formations';

import FormationForm from '../../../components/form/FormationForm';

import styles from './formation-edit.module.scss';

const CreateFormation = () => {
  const { t } = useTranslation(['global', 'formations']);
  // Create
  const { formationId } = useParams();
  const {
    data: formation,
    error: errorGet,
    isLoading: isGetLoading,
  } = useGetFormation(formationId);
  // Create
  const {
    mutateAsync: updateFormation,
    error: errorPut,
  } = useUpdateFormation();

  const formRef = useRef<any>();
  const errorApi: any = errorGet || errorPut;
  console.log(errorApi);

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;
    if (formation) {
      const { formation: updatedFormation }: any = await updateFormation(data);
      console.log(updatedFormation);
    }
  };

  return (
    <div className={styles['create-formation']}>
      <NavLink className={styles.backward} to="/formation">
        <FaArrowLeft />
        {t('back', { ns: 'global' })}
      </NavLink>
      <button disabled={isGetLoading} onClick={handleSubmit}
        className={styles.submit}
      >
        {isGetLoading && <div className={styles.dotLoader} />}
        <span>{t('save', { ns: 'global' })}</span>
      </button>
      <div className={styles.content}>
        {formation && <FormationForm ref={formRef} formation={formation}/> }
      </div>
    </div>
  );
};

export default CreateFormation;
