import React, { useState, useRef, useCallback } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUser, useUpdateUser, useDeleteUser } from '../../../../../hooks/users';
import withModalConfirm from '../../../../../lib/withModalConfirm';
import ClientForm from '../../../../../components/form/ClientForm';
import { ErrorField } from '../../../../../lib/HooksFormFields';
import styles from './edit-organisation-member.module.scss';

const EditOrganisationMember = ({ confirm } : { confirm: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['createUser', 'global']);
  const { organisationId, userId } = useParams();
  const formUserRef = useRef<any>();
  const {
    mutateAsync: updateClient,
    isLoading: isPutLoading,
    error: updateUserError,
  } = useUpdateUser();
  const { mutateAsync: deleteUser, error: errorDelete } = useDeleteUser();
  const [skipFetchingPut, setSkipFetchingPut] = useState(false);
  const { data: user } = useGetUser(!skipFetchingPut ? userId : undefined);

  const error: any = updateUserError || errorDelete;

  const handleSubmit = async () => {
    const data = await formUserRef.current.submit();
    if (!data) return;
    await updateClient({
      _id: userId,
      ...data,
    });
  };

  const handleDeleteUser = useCallback(async () => {
    if (!user) return;

    confirm(
      t('confirmDelete', { ns: 'createUser' }),
      async () => {
        setSkipFetchingPut(true);
        const res: any = await deleteUser(user._id);
        if (res?.error) {
          setSkipFetchingPut(false);
          return;
        }
        navigate(`/organisations/detail/${organisationId}/users`, { replace: true });
      },
    );
  }, [user]);

  return (
    <div className={styles.editMember}>
      <div className={styles.save}>
        {user && (
          <p className={styles.createdAt}>
            {t('createdAt', { ns: 'createUser' })} {`${format(new Date(user.createdAt), 'dd / MM / yy', { locale: fr })}`}
          </p>
        )}
        <button onClick={handleSubmit}>
          {isPutLoading && <div className={styles.dotLoader} />}
          <span>{t('save', { ns: 'global' })}</span>
        </button>
      </div>
      <div className={styles.content}>
        {user && (
          <ClientForm
            ref={formUserRef}
            organisationId={organisationId}
            user={{
              ...user,
            }}
          />
        )}
        {error?.message && (
          <ErrorField message={error.message} />
        )}
      </div>
      {(!isPutLoading && !error?.message && user) && (
        <p className={styles.delete} onClick={() => handleDeleteUser()}>{t('deleteUser', { ns: 'createUser' })}</p>
      )}
    </div>
  );
};

export default withModalConfirm(EditOrganisationMember);
