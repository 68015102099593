/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  IFormation,
  ArgsGetFormations,
} from '../../types/formations';

const getFormations = async (params: ArgsGetFormations) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/formations`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.formations?.formations;
};

const getFormation = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/formations/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.formation;
};

const duplicateFormation = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/formations/duplicate/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.formation;
};

const getFormationsList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/formations/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

// // eslint-disable-next-line max-len
const createFormation = async (data: Partial<IFormation>)
: Promise<{ formation: IFormation }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/formations`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

// eslint-disable-next-line max-len
const updateFormation = async (data: Partial<IFormation>)
: Promise<{ formation: IFormation }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/formations/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const deleteFormation = async (id: string)
: Promise<{ formation: IFormation }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/formations/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getFormations,
  getFormation,
  getFormationsList,
  createFormation,
  updateFormation,
  duplicateFormation,
  deleteFormation,
};
