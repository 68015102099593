import React, {
  forwardRef, useImperativeHandle,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  ErrorField,
} from '../../../lib/HooksFormFields';
import { ITag } from '../../../types/tags';
import styles from './tag-form.module.scss';

const TagForm = forwardRef(({ tag } : { tag?: ITag }, ref) => {
  const { t } = useTranslation('tags');
  const {
    control,
    trigger,
    getValues,
    watch,
    formState: { errors },
  } = useForm(tag ? {
    defaultValues: tag,
  } : {});

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
      }
      return data;
    },
  }), []);

  return (
    <div className={styles.form}>
      <section>
        <h3>{t('desc')}</h3>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name="name.fr"
              control={control}
              label={t('fieldFr')}
              rules={{
                required: t('requiredField', { ns: 'global' }),
              }}
            />
            {errors?.name && <ErrorField message={(errors as any).name.fr.message} />}
          </div>
          <div className={styles.containerField} />
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name="name.en"
              control={control}
              label={t('fieldEn')}
              rules={{
                required: t('requiredField', { ns: 'global' }),
              }}
            />
            {errors?.name && <ErrorField message={(errors as any).name.fr.message} />}
          </div>
          <div className={styles.containerField}></div>
        </div>
        <label>{t('color')}</label>
        <div className={styles.row}>
          <div className={styles.color}>
            <div className={styles.select}
              style={{ backgroundColor: watch('color') }}
            >
              </div>
              <Controller
                name="color"
                control={control}
                defaultValue="#B3DBCF"
                render={({ field }: any) => (
                  <input
                    type="color"
                    id="color"
                    {...field}
                  />
                )}
                />
            </div>
        </div>
      </section>
    </div>
  );
});

export default TagForm;
