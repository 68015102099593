const difficulitiesColors = [
  {
    label: 'low',
    color: '#80C3AE',
  },
  {
    label: 'medium',
    color: '#FF845E',
  },
  {
    label: 'high',
    color: '#DA2700',
  },
];

export default difficulitiesColors;
