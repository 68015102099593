import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../../types/users';
import { useGetUsersList } from '../../../hooks/users';

import {
  InputText,
  InputEmail,
  InputFormatPattern,
  ErrorField,
  Textarea,
  Checkbox,
} from '../../../lib/HooksFormFields';
// import SelectMultiple from '../../SelectMultiple';
import styles from './client-form.module.scss';
import useOptionsLang from '../../../hooks/useOptionsLang';
import InputDate from '../../../lib/HooksFormFields/InputDate';
import SelectMultiple from '../../SelectMultiple';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';

// eslint-disable-next-line max-len
const ClientForm = forwardRef(({ user, organisationId } : { user?: IUser, organisationId?: string }, ref) => {
  const { t } = useTranslation(['createUser', 'global']);
  const { data: list } = useGetUsersList({
    organisations: organisationId,
  });

  // const facilityOptions = useTagsToOptionsLang(list?.facilities || []);
  const userRoles = useOptionsLang(list?.userRoles || []).filter((r:any) => r.value !== 'Admin');
  const tagsTranslated = useTagsToOptionsLang(list?.tags || []);

  const {
    control,
    trigger,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm(user ? {
    defaultValues: user,
  } : {});

  const errorsForm : any = errors;

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        tags: user?.tags?.map((tag: any) => tag._id) || [],
      });
    }
  }, [user]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
        data = {
          email: data.email,
          phone: data.phone,
          role: data.role,
          birthDate: data?.birthDate,
          tags: data.tags,
          note: data.note,
          isSpecifik: data.isSpecifik,
          profile: {
            lastName: data.profile.lastName,
            firstName: data.profile.firstName,
          },
        };
      }
      return data;
    },
  }), []);

  return (
    <div className={styles.form}>
      <div className={styles.roles}>
        {userRoles.map((r: any) => (
          <button
            key={r.value}
            type="button"
            onClick={() => setValue('role', r.value)}
            className={`${styles.role} ${r.value === watch('role') ? styles.active : ''}`}>
            {r.label}
          </button>
        ))}
      </div>
      <h3>{t('title')}</h3>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="profile.lastName"
            control={control}
            label={t('fields.lastName', { ns: 'createUser' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.profile?.lastName && (
            <ErrorField message={errorsForm.profile.lastName.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputText
            name="profile.firstName"
            control={control}
            label={t('fields.firstName', { ns: 'createUser' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.profile?.firstName && (
            <ErrorField message={errorsForm.profile.firstName.message} />
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputFormatPattern
            name="phone"
            control={control}
            label={t('fields.phone', { ns: 'createUser' })}
            format="## ## ## ## ##"
            rules={{
              pattern: {
                value: /([0-9]{2,2}\s){4,5}([0-9]{2,2})/,
                message: t('required.formatPhone', { ns: 'createUser' }),
              },
            }}
          />
          {errorsForm?.phone && (
            <ErrorField message={errorsForm?.phone.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputEmail
            name="email"
            control={control}
            required
            label={t('fields.email', { ns: 'createUser' })}
            className="login"
          />
          {errorsForm.email && <ErrorField message={errorsForm.email.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputDate
            name="birthDate"
            control={control}
            label={t('fields.birthDate', { ns: 'createUser' })}
            rules={{
            }}
          />
        </div>
      </div>
      <div className={styles.tags}>
        <SelectMultiple
          label={t('title', { ns: 'tags' })}
          name="tags"
          control={control}
          options={tagsTranslated}
        />
      </div>
      {watch('role') === 'Learner'
        && <div className={styles.check}>
          <div className={styles.containerField}>
            <Checkbox
              name="isSpecifik"
              control={control}
              label={t('fields.isSpecifik', { ns: 'createUser' })}
              rules={{
              }}
            />
          </div>
        </div>
      }
      <div className={styles.note}>
        <Textarea
          name="note"
          control={control}
          label={t('fields.note', { ns: 'createOrganisation' })}
        />
      </div>
    </div>
  );
});

export default ClientForm;
