import axios from 'axios';
import { ArgsGetStats } from '../../types/stats';

const getDashboard = async (params: ArgsGetStats) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.dashboard;
};

const getStatsGlobal = async (params: ArgsGetStats) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/stats`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.stats;
};

const getStatsFormations = async (params: ArgsGetStats) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/stats/formations`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.stats;
};

export {
  getDashboard,
  getStatsGlobal,
  getStatsFormations,
};
