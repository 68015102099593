import React from 'react';
import {
  Area, AreaChart, ResponsiveContainer, Tooltip,
} from 'recharts';

import styles from './lines.module.scss';

function CustomTooltip({
  active,
  payload,
}:{
  active?: boolean;
  payload?: { name: string; value: number; stroke: string, color: string }[];
}) {
  if (active && payload && payload.length) {
    return (
      <div style={{
        backgroundColor: '#fff', border: '1px solid #ddd', padding: '10px', borderRadius: '4px',
      }}
      >
        {payload.map((entry: any) => (
          <p key={entry.name} style={{ margin: 0 }}>
            <span style={{ color: entry.color }}>
              {entry.payload.name}
              {' '}
              :
              {' '}
            </span>
            <span>{entry.value} {entry?.payload?.unit}</span>
          </p>
        ))}
      </div>
    );
  }
  return null;
}

export type DashboardItem = {
  name: string,
  value: number,
  color?: string,
  unit: string,
};

function CustomLineChart({ data, color = '#FF845E' }:{ data: DashboardItem[], color?: string }) {
  return (
    <div className={styles.lines}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4C549E" stopOpacity={0.3} />
              <stop offset="90%" stopColor="#4C549E" stopOpacity={0.1} />
              <stop offset="100%" stopColor="#4C549E" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="value" strokeWidth={3} fillOpacity={1} fill="url(#color)" stroke={color} dot={false} />
          <Tooltip content={<CustomTooltip />} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CustomLineChart;
