import React, {
  forwardRef, useEffect, useImperativeHandle,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  InputText,
  ErrorField,
  Textarea,
  StyledSelect,
} from '../../../lib/HooksFormFields';
import styles from './session-form.module.scss';
import { useGetSessionsList } from '../../../hooks/session';
import { ISession } from '../../../types/sessions';
import InputDateTime from '../../../lib/HooksFormFields/InputDateTime';
import InputDate from '../../../lib/HooksFormFields/InputDate';

const SessionForm = forwardRef(({
  session = null,
} : {
  session?: ISession | null
}, ref) => {
  const { t } = useTranslation(['global', 'formations']);

  const { data: list } = useGetSessionsList();
  const {
    control,
    trigger,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm(session ? {
    defaultValues: session,
  } : {
  });

  console.log(list);
  const form = watch();
  const errorsForm : any = errors;

  useEffect(() => {
    if (session) {
      reset({
        ...session,
        date: session?.date?.toString().slice(0, 16),
        expirationDate: session?.expirationDate?.toString().slice(0, 16),
      });
    }
  }, [session]);

  useEffect(() => {
    if (form.date) {
      const date = new Date(form.date);
      date.setFullYear(date.getFullYear() + 2);
      const expirationDate = format(date, 'yyyy-MM-dd');
      setValue('expirationDate', expirationDate);
    }
  }, [form.date]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
        data = {
          _id: data?._id,
          name: data.name,
          date: data.date,
          note: data.note,
          expirationDate: data?.expirationDate && new Date(data.expirationDate).toISOString(),
          examCenter: data.examCenter,
          quiz: data.quiz,
        };
      }
      return data;
    },
  }), []);

  return (
    <div>
      {session?.sessionId
        && <div className={styles.row2}>
          <div className={`${styles.containerField}`}>
            <InputText
              name="sessionId"
              control={control}
              label={t('form.fields.sessionId', { ns: 'sessions' })}
            />
            {errorsForm?.sessionId && <ErrorField message={errorsForm.sessionId.message} />}
          </div>
        </div>
      }
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="name"
            control={control}
            label={t('form.fields.name', { ns: 'sessions' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.name && <ErrorField message={errorsForm.name.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputDateTime
            name="date"
            control={control}
            label={t('form.fields.date', { ns: 'sessions' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.date && <ErrorField message={errorsForm.date.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <StyledSelect
            name="examCenter"
            control={control}
            label={t('form.fields.center', { ns: 'sessions' })}
            options={list?.examCenters || []}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
            isClearable
          />
          {errorsForm?.examCenter && <ErrorField message={errorsForm.examCenter.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputDate
            name="expirationDate"
            control={control}
            label={t('form.fields.expirationDate', { ns: 'sessions' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.date && <ErrorField message={errorsForm.date.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <StyledSelect
            name="quiz"
            control={control}
            label={t('form.fields.quiz', { ns: 'sessions' })}
            options={list?.quiz || []}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
            isClearable
          />
          {errorsForm?.quiz && <ErrorField message={errorsForm.quiz.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <Textarea
            name="note"
            control={control}
            label={t('form.fields.description', { ns: 'sessions' })}
            rules={{ }}
          />
          {errorsForm?.description && <ErrorField message={errorsForm.description.message} />}
        </div>
      </div>
    </div>
  );
});

export default SessionForm;
