import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

import { IoTrash } from 'react-icons/io5';
import styles from './learners-table.module.scss';
import { useGetUsersList } from '../../hooks/users';
import useOptionsLang from '../../hooks/useOptionsLang';
import { IOption } from '../../types/options';
import { IOrganisation } from '../../types/organisations';
import Tags from '../Tags';

const LearnersTable = (
  {
    users = [],
    isSession = false,
    handleSorting,
    deleteSessionUser,
  } : {
    users: any[],
    isSession?: boolean,
    handleSorting?: any,
    deleteSessionUser?: any,
  },
) => {
  const { t } = useTranslation('organisationUsers');

  const columns = isSession ? [{
    header: t('columns.lastName'),
    accessorKey: 'name',
  },
  {
    header: t('columns.userId', { ns: 'sessions' }),
    accessorKey: 'userId',
  }, {
    header: t('columns.tags'),
    cell: (row: any) => <Tags tags={(row.getValue())}/>,
    accessorKey: 'tags',
  }, {
    header: t('columns.organisations'),
    cell: (row: any) => {
      const userId = row.row?.original?._id;
      const organistions = row?.getValue()?.map(
        (o: IOrganisation, i: number) => (<span key={o._id}>{i > 0 && ' / '}{o.name}</span>),
      );
      return <div>
        {organistions}
        <div
          className={styles.delete}
          onClick={() => deleteSessionUser(userId)}
        >
          <IoTrash />
        </div>
      </div>;
    },
    accessorKey: 'organisations',
  }] : [{
    header: t('columns.lastName'),
    accessorKey: 'name',
  }, {
    header: t('columns.tags'),
    cell: (row: any) => <Tags tags={(row.getValue())}/>,
    accessorKey: 'tags',
  }, {
    header: t('columns.organisations'),
    cell: (row: any) => {
      const organistions = row?.getValue()?.map(
        (o: IOrganisation, i: number) => (<span key={o._id}>{i > 0 && ' / '}{o.name}</span>),
      );
      return <div>
        {organistions}
      </div>;
    },
    accessorKey: 'organisations',
  }];

  const { data: list } = useGetUsersList();
  const [sorting, setSorting] = useState<any>([]);

  const userRoles = useOptionsLang(list?.userRoles || []);

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => users, [users]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const getUserRoleLabel = useCallback((role: any) => (
    (userRoles || []).find((opt: IOption) => opt.value === role)?.label
  ), [userRoles]);

  return (
    <div className={styles.learnersTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === 'role' ? (
                    <>
                      {flexRender(
                        cell.column.columnDef.cell,
                        {
                          ...cell.getContext(),
                          value: getUserRoleLabel(cell.getValue() || ''),
                        },
                      )}
                    </>
                  ) : (
                    <>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LearnersTable;
