import React, {
  useState, useCallback, useMemo, useRef,
} from 'react';
// import { IoAdd } from 'react-icons/io5';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
// import { useAppDispatch } from '../../../stores';
import { useGetUser, useUpdateUser, useDeleteUser } from '../../../hooks/users';
import { ErrorField } from '../../../lib/HooksFormFields';
import styles from './edit.module.scss';
import AdminForm from '../../../components/form/AdminForm';
import withModalConfirm from '../../../lib/withModalConfirm';

const Edit = ({ confirm } : {
  confirm: any
}) => {
  // const dispatch = useAppDispatch();
  const { t } = useTranslation(['createUser', 'global']);
  const params = useParams();
  const navigate = useNavigate();
  const formUserRef = useRef<any>();
  const {
    mutateAsync: updateClient,
    isLoading: isPostLoading,
    error: errorUpdateUser,
  } = useUpdateUser();
  const { mutateAsync: deleteUser, error: errorDelete } = useDeleteUser();
  const [skipFetchingPost, setSkipFetchingPost] = useState(false);
  const { data: user } = useGetUser(!skipFetchingPost ? params?.id : undefined);
  const error: any = errorUpdateUser || errorDelete;

  const handleSubmit = async () => {
    if (!user) return;
    const data = await formUserRef.current.submit();
    updateClient({
      _id: user?._id,
      ...data,
    });
  };

  const handleDeleteUser = useCallback(async () => {
    if (!user) return;

    confirm(
      t('confirmDelete', { ns: 'createUser' }),
      async () => {
        setSkipFetchingPost(true);
        const res: any = await deleteUser(user._id);
        if (res?.error) {
          setSkipFetchingPost(false);
          return;
        }
        navigate(user.role === 'Member' ? '/users/clients' : '/users/tepik-team', { replace: true });
      },
    );
  }, [user]);

  const userData = useMemo(() => (user ? {
    lastName: user.profile.lastName,
    firstName: user.profile.firstName,
    email: user.email,
    role: user.role,
    phone: user.phone,
    organisations: user.organisations.map((o:any) => o._id),
  } : null), [user]);

  return (
    <div className={styles.edit}>
      <div className={styles.header}>
        <NavLink className={styles.backward} to={'/users/tepik-team'}>
          <FaArrowLeft />
        </NavLink>
        <button onClick={handleSubmit}>
          {isPostLoading && <div className={styles.dotLoader} />}
          <span>{t('save', { ns: 'global' })}</span>
        </button>
      </div>
      <div className={styles.form}>
        {(userData?.role === 'Admin' || userData?.role === 'Owner') && (
          <AdminForm
            ref={formUserRef}
            user={userData}
          />
        )}
        {/* {error?.message && (
          <ErrorField message={error.message} />
        )} */}
         {error?.response?.data?.error === 'La valeur du ou des champs suivant est déjà utilisé : email.' && (
          <ErrorField message={t('emailUsed')} />
         )}
      </div>
      {(!isPostLoading && !error?.message && user) && (
        <p className={styles.delete} onClick={() => handleDeleteUser()}>{t('deleteUser', { ns: 'createUser' })}</p>
      )}
    </div>
  );
};

export default withModalConfirm(Edit);
