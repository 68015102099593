import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import { NavLink, useNavigate } from 'react-router-dom';

import styles from './session-create.module.scss';
import { useCreateSession } from '../../../hooks/session';
import SessionForm from '../../../components/form/SessionForm';

const CreateSession = () => {
  const { t } = useTranslation(['global', 'session']);
  const navigate = useNavigate();

  const {
    mutateAsync: createSession,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreateSession();

  const formRef = useRef<any>();
  const errorApi: any = errorPost;
  console.log(errorApi);

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;
    const { examSession: createdSession }: any = await createSession(data);
    if (createdSession) navigate(`/session/detail/${createdSession?._id}`);
  };

  return (
    <div className={styles['create-session']}>
      <NavLink className={styles.backward} to="/session">
        <FaArrowLeft />
        {t('back', { ns: 'global' })}
      </NavLink>
      <button disabled={isPostLoading} onClick={handleSubmit}
        className={styles.submit}
      >
        {isPostLoading && <div className={styles.dotLoader} />}
        <span>{t('save', { ns: 'global' })}</span>
      </button>
      <div className={styles.content}>
        <SessionForm ref={formRef} />
      </div>
    </div>
  );
};

export default CreateSession;
