import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import {
  IoAdd, IoClose, IoSearch, IoTrash,
} from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import organisationIcon from './images/organisation.svg';
import userIcon from './images/user.svg';
import useTagsToOptionsLang, { ITag } from '../../hooks/useTagsToOptionsLang';
import { IOrganisation } from '../../types/organisations';
import { IUser } from '../../types/users';
import ButtonExpandable from '../ButtonExpandable';
import FilterSearchParam from '../FilterSearchParam';
import Tags from '../Tags';
import styles from './ShareModal.module.scss';

export default function ShareModal({
  close,
  learners = [],
  organisations = [],
  list = {},
  update,
  isLoading,
}:{
  close: () => void,
  update: (obj: any) => void,
  learners: string[],
  organisations: string[]
  list: any,
  isLoading: boolean,
}) {
  const { t } = useTranslation('global');
  const [searchParams, setSearchParams] = useSearchParams();
  const [addType, setAddType] = useState<string | null>(null);

  const users = list?.learners || [];
  const tags = useTagsToOptionsLang(list?.tags || []);
  const [search, setSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<string[]>([]);
  const [deletedOrganisations, setDeletedOrganisations] = useState<string[]>([]);
  const organisationsList = list?.organisations || [];
  const organisationsOptions = useMemo(() => organisationsList.map(
    (o: IOrganisation) => ({ value: o._id, label: o.name }),
  ), [organisationsList]);

  const organistion = searchParams.get('share.organistion');
  const tag = searchParams.get('share.tag');

  const usersFiltered = useMemo(() => users.filter((u: IUser) => {
    if (learners.find((l: string) => l === u._id)) {
      return null;
    }
    if (organistion && !(u.organisations as any)?.find(
      (o: IOrganisation) => o._id === organistion,
    )) {
      return null;
    }
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (search
      && !u.profile.firstName.toLowerCase().includes(search.toLowerCase())
      && !u.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
      return null;
    }
    return u;
  }), [users, organistion, learners, tag, search]);

  const currentUsers = useMemo(() => users.filter((u: IUser) => {
    if (organistion && !(u.organisations as any)?.find(
      (o: IOrganisation) => o._id === organistion,
    )) {
      return null;
    }
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (search
      && !u.profile.firstName.toLowerCase().includes(search.toLowerCase())
      && !u.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
      return null;
    }
    if (learners.find((l: string) => l === u._id)) {
      return u;
    }
    return null;
  }), [users, learners, organistion, tag, search]);

  const organisationsFiltered = useMemo(() => organisationsList.filter((u: IOrganisation) => {
    if (organisations.find((l: string) => l === u._id)) {
      return null;
    }
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (search && !u.name.toLowerCase().includes(search.toLowerCase())) {
      return null;
    }
    return u;
  }), [organisationsList, organisations, tag, search]);

  const currentOrganisations = useMemo(() => organisationsList.filter((u: IOrganisation) => {
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (organistion && organistion !== u._id) {
      return null;
    }
    if (search && !u.name.toLowerCase().includes(search.toLowerCase())) {
      return null;
    }
    if (organisations.find((l: string) => l === u._id)) {
      return u;
    }
    return null;
  }), [organisationsList, organisations, tag, search]);

  function handleSelectUser(id: string) {
    let usersSelected : string[] = [...selectedUsers];
    if (usersSelected.find((u: string) => u === id)) {
      usersSelected = usersSelected.filter((u: string) => u !== id);
    } else {
      usersSelected.push(id);
    }
    setSelectedUsers(usersSelected);
  }

  function handleDeleteUser(id: string) {
    let usersDeleted : string[] = [...deletedUsers];
    if (usersDeleted.find((u: string) => u === id)) {
      usersDeleted = usersDeleted.filter((u: string) => u !== id);
    } else {
      usersDeleted.push(id);
    }
    setDeletedUsers(usersDeleted);
  }

  function handleSelectOrganisation(id: string) {
    let organisationsSelected : string[] = [...selectedOrganisations];
    if (organisationsSelected.find((u: string) => u === id)) {
      organisationsSelected = organisationsSelected.filter((u: string) => u !== id);
    } else {
      organisationsSelected.push(id);
    }
    setSelectedOrganisations(organisationsSelected);
  }

  function handleDeleteOrganisation(id: string) {
    let organisationsDeleted : string[] = [...deletedOrganisations];
    if (organisationsDeleted.find((u: string) => u === id)) {
      organisationsDeleted = organisationsDeleted.filter((u: string) => u !== id);
    } else {
      organisationsDeleted.push(id);
    }
    setDeletedOrganisations(organisationsDeleted);
  }

  async function handleCancelAdd() {
    setAddType(null);
    setSelectedOrganisations([]);
    setSelectedUsers([]);
  }

  async function handleAddShare() {
    const learnersUpdated : any = [...selectedUsers, ...learners];
    const organisationsUpdated: any = [...selectedOrganisations, ...organisations];
    setAddType(null);
    setSelectedUsers([]);
    setSelectedOrganisations([]);
    searchParams.delete('share.organistion');
    searchParams.delete('share.tag');
    setSearchParams(searchParams);
    await update({
      learners: learnersUpdated,
      organisations: organisationsUpdated,
    });
  }

  async function handleDeleteShare() {
    const learnersUpdated : any = [...learners.filter(
      (l) => !deletedUsers.find((d) => d === l),
    )];
    const organisationsUpdated: any = [...organisations.filter(
      (o) => !deletedOrganisations.find((d) => d === o),
    )];
    setSelectedUsers([]);
    setSelectedOrganisations([]);
    setDeletedOrganisations([]);
    setDeletedUsers([]);
    searchParams.delete('share.organistion');
    searchParams.delete('share.tag');
    setSearchParams(searchParams);
    await update({
      learners: learnersUpdated,
      organisations: organisationsUpdated,
    });
  }

  return (
    <div className={styles.container}>
      <header>
        <h2>{t('share.title')}</h2>
        {!addType
          ? <button
              type="button"
              className={styles.cancel}
              onClick={() => close()}
            >
              {t('share.modal.close')}
            </button>
          : <div className={styles.tabs}>
            <button
              type='button'
              className={addType === 'learners' ? styles.active : ''}
              onClick={() => setAddType('learners')}
            >
              {t('share.users')}
              <span />
            </button>
            <button
              type='button'
              className={addType === 'organisation' ? styles.active : ''}
              onClick={() => setAddType('organisation')}
            >
              {t('share.organisations')}
              <span />
            </button>
          </div>
        }
      </header>
      <div className={styles.search}>
        <div className={styles.input}>
          <input
            type='text'
            value={search}
            placeholder={t('share.modal.search')}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className={`${styles.icon} ${search ? styles.active : ''}`}
            onClick={() => setSearch('')}
          >
            {search ? <IoClose /> : <IoSearch /> }
          </div>
        </div>
        <div className={styles.filters}>
          <FilterSearchParam
            label={t('filters.organisation', { ns: 'formations' })}
            name='share.organistion'
            options={organisationsOptions || []}
          />
          <FilterSearchParam
            label={t('filters.tags', { ns: 'formations' })}
            name='share.tag'
            options={tags || []}
          />
        </div>
      </div>
      <div className={styles.add}>
        {!addType
          && <div
            onClick={() => setAddType('organisation')}
          >
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('share.modal.members')}
            />
          </div>
        }
      </div>
      {!addType
      && <>
          <div className={`${styles.row} ${styles.label}`}>
            <div className={styles.col}>
            </div>
            <div className={styles.col}>
              <p>{t('share.modal.label.name')}</p>
            </div>
            <div className={styles.col}>
              <p>{t('share.modal.label.tags')}</p>
            </div>
          </div>
          <div className={styles.list}>
            {currentOrganisations.map((o: IOrganisation) => (
              <div key={o._id} className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.check}
                    onClick={() => handleDeleteOrganisation(o._id)}
                  >
                    {deletedOrganisations.find((s: string) => s === o._id) && <IoTrash />}
                  </div>
                </div>
                <div className={styles.col}>
                  <img src={organisationIcon} alt={o.name} /><p>{o.name}</p>
                </div>
                <div className={styles.col}>
                  <Tags tags={o.tags}/>
                </div>
              </div>
            ))}
            {currentUsers.map((u: IUser) => (
              <div key={u._id} className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.check}
                    onClick={() => handleDeleteUser(u._id)}
                  >
                    {deletedUsers.find((s: string) => s === u._id) && <IoTrash />}
                  </div>
                </div>
                <div className={styles.col}>
                  <img src={userIcon} alt={u.profile.firstName} />
                  <p>{u.profile.firstName} {u.profile.lastName}</p>
                </div>
                <div className={styles.col}>
                  <Tags tags={u.tags}/>
                </div>
              </div>
            ))}
          </div>
        </>
      }
      {addType === 'learners'
      && <>
          <div className={`${styles.row} ${styles.label}`}>
            <div className={styles.col}>
            </div>
            <div className={styles.col}>
              <p>{t('share.modal.label.learner')}</p>
            </div>
            <div className={styles.col}>
              <p>{t('share.modal.label.tags')}</p>
            </div>
          </div>
          <div className={styles.list}>
            {usersFiltered.map((u: IUser) => (
              <div key={u._id} className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.check}
                    onClick={() => handleSelectUser(u._id)}
                  >
                    {selectedUsers.find((s: string) => s === u._id) && <FaCheck />}
                  </div>
                </div>
                <div className={styles.col}>
                  <img src={userIcon} alt={u.profile.firstName} />
                  <p>{u.profile.firstName} {u.profile.lastName}</p>
                </div>
                <div className={styles.col}>
                  <Tags tags={u.tags}/>
                </div>
              </div>
            ))}
          </div>
        </>
      }
      {addType === 'organisation'
      && <>
          <div className={`${styles.row} ${styles.label}`}>
            <div className={styles.col}>
            </div>
            <div className={styles.col}>
              <p>{t('share.modal.label.organisation')}</p>
            </div>
            <div className={styles.col}>
              <p>{t('share.modal.label.tags')}</p>
            </div>
          </div>
          <div className={styles.list}>
            {organisationsFiltered.map((o: IOrganisation) => (
              <div key={o._id} className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.check}
                    onClick={() => handleSelectOrganisation(o._id)}
                  >
                    {selectedOrganisations.find((s: string) => s === o._id) && <FaCheck />}
                  </div>
                </div>
                <div className={styles.col}>
                  <img src={organisationIcon} alt={o.name} /><p>{o.name}</p>
                </div>
                <div className={styles.col}>
                  {/* <Tags tags={o.tags}/> */}
                </div>
              </div>
            ))}
          </div>
        </>
      }
      <div className={styles.footer}>
        <div className={styles.shared}>
          {learners.length > 0 && <p><span>{learners.length}</span> {t('share.users')}</p>}
          {organisations.length > 0 && <p><span>{organisations.length}</span> {t('share.organisations')}</p>}
        </div>
        {isLoading
          ? <p></p>
          : <div className={styles.actions}>
        {addType
          ? <>
              <button
                type="button"
                className={styles.cancel}
                onClick={() => handleCancelAdd()}
              >
                {t('share.modal.cancel')}
              </button>
              <div
              onClick={() => handleAddShare()}
            >
              <ButtonExpandable
                icon={<IoAdd size={24} />}
                text={t('share.modal.add')}
              />
            </div>
          </>
          : <>
              {(deletedOrganisations.length > 0 || deletedUsers.length > 0)
                && <div
                onClick={() => handleDeleteShare()}
              >
                <ButtonExpandable
                  icon={<IoTrash size={15} />}
                  text={t('share.modal.delete')}
                />
              </div>
              }
          </>
        }
        </div>
        }
      </div>
    </div>
  );
}
