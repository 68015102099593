import React from 'react';
import { IoAdd } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import styles from './list.module.scss';

import InputSearch from '../../../components/InputSearch';

import ButtonExpandable from '../../../components/ButtonExpandable';
import FilterSearchParam from '../../../components/FilterSearchParam';

import { useGetSessions, useGetSessionsList } from '../../../hooks/session';
import { ISession } from '../../../types/sessions';
import SessionsTable from '../../../components/SessionsTable';

const Sessions = () => {
  const { t } = useTranslation('sessions');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const debouncedSearch = useDebounce<string>(search, 500);
  const { data: list } = useGetSessionsList();
  const { data: dataSessions = [], isLoading } = useGetSessions({
    ...paramsObject,
    search: debouncedSearch,
  });

  const sessions : ISession[] = dataSessions || [];

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleCreateSession = async () => {
    navigate('/session/create');
  };

  return (
    <div className={styles.listSessions}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          {sessions.length > 0 && 's'}
          <span>{sessions.length}</span>
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            defaultValue={search}
          />
          <div
            onClick={() => handleCreateSession()}
          >
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new-session')}
            />
          </div>
        </div>
      </div>
      {isLoading && <p>Loading</p>}
      <div className={styles.filters}>
         <FilterSearchParam
          label={t('filters.centers')}
          name='center'
          options={list?.examCenters || []}
        />
      </div>
      {sessions.length > 0 && (
        <SessionsTable
          sessions={sessions}
          handleSorting={handleSorting}
        />
      )}
    </div>
  );
};

export default Sessions;
