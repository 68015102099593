import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { useCreateOrganisation } from '../../../hooks/organisations';
import OrganisationForm from '../../../components/form/OrganisationForm';
import { ErrorField } from '../../../lib/HooksFormFields';
import styles from './create-organisation.module.scss';

const CreateOrganisation = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const formOrganisationsRef = useRef<any>();
  const {
    mutateAsync: createOrganisation,
    isLoading: isPostLoading,
    error,
  } = useCreateOrganisation();
  const createOrganisationsError: any = error;

  const handleSubmit = async () => {
    const data = await formOrganisationsRef.current.submit();
    if (!data) return;
    const { organisation: newOrganisation }: any = await createOrganisation(data);
    if (newOrganisation) navigate('/organisations');
  };

  return (
    <div className={styles.createOrganisation}>
      <div className={styles.header}>
        <NavLink className={styles.backward} to='/organisations'>
          <FaArrowLeft />
        </NavLink>
        <button onClick={handleSubmit}>
          {isPostLoading && <div className={styles.dotLoader} />}
          <span>{t('save')}</span>
        </button>
      </div>
      <div className={styles.content}>
        <OrganisationForm ref={formOrganisationsRef} />
        {createOrganisationsError?.message && (
          <ErrorField message={createOrganisationsError.message} />
        )}
      </div>
    </div>
  );
};

export default CreateOrganisation;
