import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useGetOrganisationsList } from '../../hooks/organisations';
import useOptionsLang from '../../hooks/useOptionsLang';
import { IOption } from '../../types/options';
import { IOrganisation } from '../../types/organisations';

import styles from './organisations-table.module.scss';
import Tags from '../Tags';

const OrganisationsTable = (
  {
    organisations,
    handleSorting,
  } : {
    organisations: IOrganisation[],
    handleSorting: any,
  },
) => {
  const { t } = useTranslation('organisations');

  const { data: list } = useGetOrganisationsList();
  const organisationTypesTranslated = useOptionsLang(list?.organisationTypes || []);

  const getUserTypeLabel = useCallback((type: any) => (
    (organisationTypesTranslated || []).find((opt: IOption) => opt.value === type)?.label
  ), [organisationTypesTranslated]);

  const columns = [{
    header: t('columns.organisationName'),
    accessorKey: 'name',
  }, {
    header: t('columns.region'),
    accessorKey: 'address.region',
  }, {
    header: t('columns.type'),
    accessorKey: 'type',
    cell: (row: any) => getUserTypeLabel(row.getValue()),
  }, {
    header: t('columns.countUsers'),
    cell: (row: any) => (<p className={styles.countUsers}>{row.getValue()}</p>),
    accessorKey: 'countUsers',
  }, {
    header: t('columns.tags'),
    cell: (row: any) => <Tags tags={(row.getValue())}/>,
    accessorKey: 'tags',
  }];

  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => organisations, [organisations]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IOrganisation>) => {
    navigate(`/organisations/detail/${row.original._id}`);
  };

  return (
    <div className={styles.organisationsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={
                    !['type', 'tags'].includes(header.id)
                      ? header.column.getToggleSortingHandler()
                      : () => {}
                  }
                >
                  {!['type', 'tags'].includes(header.id) && (
                    <>
                      {typeof header.column.getIsSorted() === 'string' ? (
                        {
                          asc: <IoChevronUpOutline />,
                          desc: <IoChevronDownOutline />,
                        }[header.column.getIsSorted() as string]
                      ) : (
                        <span className={styles.sortIcons}>
                          <IoChevronUpOutline />
                          <IoChevronDownOutline />
                        </span>
                      )}
                    </>
                  )}
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrganisationsTable;
