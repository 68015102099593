import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetUsersList } from '../../../hooks/users';
import {
  InputText,
  InputFormatPattern,
  InputEmail,
  ErrorField,
} from '../../../lib/HooksFormFields';
import SelectMultiple from '../../SelectMultiple';
import styles from './admin-form.module.scss';

type userProps = {
  lastName: string,
  firstName: string,
  email: string,
  role: string,
};

const AdminForm = forwardRef(({ user } : { user?: userProps }, ref) => {
  const { t } = useTranslation(['createUser', 'global']);
  const {
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm(user ? {
    defaultValues: user,
  } : {});

  const {
    data: list,
  } = useGetUsersList();

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
        console.log(data);
        data = {
          email: data.email,
          role: 'Admin',
          organisations: data?.organisations || [],
          phone: data.phone,
          profile: {
            lastName: data.lastName,
            firstName: data.firstName,
          },
        };
      }
      return data;
    },
  }), []);

  return (
    <div className={styles.form}>
      <h3>{t('title')}</h3>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="lastName"
            control={control}
            label={t('fields.lastName', { ns: 'createUser' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errors?.lastName && <ErrorField message={errors.lastName.message} />}
        </div>
        <div className={styles.containerField}>
          <InputText
            name="firstName"
            control={control}
            label={t('fields.firstName', { ns: 'createUser' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errors?.firstName && <ErrorField message={errors.firstName.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputEmail
            name="email"
            control={control}
            required
            label={t('fields.email', { ns: 'createUser' })}
            className="login"
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        <div className={styles.containerField}>
            <InputFormatPattern
              name="phone"
              control={control}
              label={t('fields.phone', { ns: 'createUser' })}
              format="## ## ## ## ##"
              rules={{
                pattern: {
                  value: /([0-9]{2,2}\s){4,5}([0-9]{2,2})/,
                  message: t('required.formatPhone', { ns: 'createUser' }),
                },
              }}
            />
            {errors?.phone && (
              <ErrorField message={errors?.phone.message} />
            )}
          </div>
      </div>
      {list
        && <>
            <div className={`${styles.row} ${styles.organisation}`}>
              <div className={styles.containerField}>
                <SelectMultiple
                  name="organisations"
                  control={control}
                  label={t('organisation')}
                  options={list?.organisations}
                />
              </div>
          </div>
          {/* <h3>{t('role')}</h3>
          <div className={styles.row}>
            <div className={styles.containerField}>
              <StyledSelect
                name="role"
                control={control}
                options={useOptionsLang(list?.userRoles)?.filter(
                  (u: any) => u.value === 'Admin' || u.value === 'Owner',
                )}
              />
            </div>
          </div> */}
        </>
      }
    </div>
  );
});

export default AdminForm;
