/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { ArgsGetSessions, ISession } from '../../types/sessions';
import {
  cancelSession,
  closeSession,
  createSession, deleteSession, getConnectedLearnersSession, getSession, getSessions, getSessionsList, startSession, updateSession,
} from '../../api/sessions';

const useGetSessions = (params: ArgsGetSessions) => useQuery({
  queryKey: ['Sessions', params],
  queryFn: async (): Promise<ISession[] | null> => getSessions(params),
  keepPreviousData: true,
});

const useGetSession = (id?: string) => useQuery({
  queryKey: ['Session', id],
  queryFn: async (): Promise<ISession | null | undefined> => getSession(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useGetSessionsList = () => useQuery({
  queryKey: ['Session-list'],
  queryFn: async (): Promise<any | null> => getSessionsList(),
  keepPreviousData: true,
});

const useCreateSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ISession>) => createSession(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Sessions'] });
    },
  });
};

const useUpdateSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ISession>) => updateSession(data),
    onSuccess: async ({ examSession }) => {
      queryClient.invalidateQueries({ queryKey: ['Sessions'] });
      queryClient.setQueryData(['Session', examSession._id], examSession);
    },
  });
};

const useStartSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => startSession(id),
    onSuccess: async ({ examSession }) => {
      queryClient.invalidateQueries({ queryKey: ['Sessions'] });
      queryClient.setQueryData(['Session', examSession._id], examSession);
    },
  });
};

const useCloseSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => closeSession(id),
    onSuccess: async ({ examSession }) => {
      queryClient.invalidateQueries({ queryKey: ['Sessions'] });
      queryClient.setQueryData(['Session', examSession._id], examSession);
    },
  });
};

const useCancelSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => cancelSession(id),
    onSuccess: async ({ examSession }) => {
      queryClient.invalidateQueries({ queryKey: ['Sessions'] });
      queryClient.setQueryData(['Session', examSession._id], examSession);
    },
  });
};

const useGetConnectedLearners = (params: any) => useQuery({
  queryKey: ['SessionLearners', params],
  queryFn: getConnectedLearnersSession,
  refetchInterval: params.sessionId && params.startAt ? 15000 : false,
  enabled: !!params.sessionId && !!params.startAt,
});

const useDeleteSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteSession(id),
    onSuccess: async (_: any, variables: any) => {
      queryClient.invalidateQueries({ queryKey: ['Sessions'] });
      queryClient.setQueryData(['Session', variables], null);
    },
  });
};

export {
  useGetSessions,
  useGetSessionsList,
  useStartSession,
  useGetSession,
  useCreateSession,
  useUpdateSession,
  useDeleteSession,
  useCloseSession,
  useCancelSession,
  useGetConnectedLearners,
};
