import * as React from 'react';

const IconOrganisations = ({ fill = '#949494', ...props } : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M13 5.95v6.01c0 .276-.114.54-.317.735a1.107 1.107 0 0 1-.766.305H9.208c-.287 0-.563-.11-.766-.305a1.02 1.02 0 0 1-.317-.735v-2.6a.51.51 0 0 0-.159-.368.553.553 0 0 0-.383-.152H5.417a.553.553 0 0 0-.383.152.51.51 0 0 0-.159.368v2.6c0 .276-.114.54-.317.735a1.107 1.107 0 0 1-.766.305H1.083c-.287 0-.563-.11-.766-.305A1.02 1.02 0 0 1 0 11.96V5.95c0-.144.031-.286.091-.418s.149-.25.26-.348L5.766.278 5.774.27c.2-.175.46-.271.729-.271a1.109 1.109 0 0 1 .736.278l5.417 4.906c.11.098.196.217.255.349.06.132.09.274.089.417Z"
    />
  </svg>
);

export default IconOrganisations;
