/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  ICenter,
  ArgsGetCenters,
} from '../../types/centers';

const getCenters = async (params: ArgsGetCenters) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/exam-centers`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.examCenters;
};

const getCenter = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/exam-centers/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.examCenter;
};

const getCentersList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/exam-centers/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const createCenter = async (data: Partial<ICenter>): Promise<{ examCenter: ICenter }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/exam-centers`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const updateCenter = async (data: Partial<ICenter>): Promise<{ examCenter: ICenter }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/exam-centers/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getCenters,
  getCenter,
  getCentersList,
  createCenter,
  updateCenter,
};
