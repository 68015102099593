import React from 'react';
import { IoAdd } from 'react-icons/io5';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import styles from './list.module.scss';
import {
  useGetPlantsLists,
} from '../../../hooks/plantsList';

import { useGetFormationsList } from '../../../hooks/formations';
import InputSearch from '../../../components/InputSearch';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
// import CentersTable from '../../../components/CentersTable';
import ButtonExpandable from '../../../components/ButtonExpandable';
import FilterSearchParam from '../../../components/FilterSearchParam';
import PlantsListsTable from '../../../components/PlantsListsTable';

const Centers = () => {
  const { t } = useTranslation('plantsLists');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const debouncedSearch = useDebounce<string>(search, 500);
  const { data: list } = useGetFormationsList();

  const { data, isLoading } = useGetPlantsLists({
    ...paramsObject,
    search: debouncedSearch,
  });
  const plantsLists = data?.plantsLists || [];

  const tags = useTagsToOptionsLang(list?.tags || []);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    let sortKey = sort.id.replace('_', '.');
    if (sortKey === 'creator') {
      sortKey = 'creator.lastName';
    }
    if (sortKey === 'plants') {
      sortKey = 'count';
    }
    if (sortKey !== 'tags') {
      params.set('sortKey', sortKey);
    }
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.plantsLists}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          <span>{plantsLists.length}</span>
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            defaultValue={search}
          />
          <NavLink to="/plants/create">
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new-list')}
            />
          </NavLink>
        </div>
      </div>
      {isLoading && <p>Loading</p>}
      <div className={styles.filters}>
        <FilterSearchParam
          label={t('filters.tag')}
          name={'tag'}
          options={tags || []}
        />
      </div>
      {plantsLists.length > 0 && (
        <PlantsListsTable
          plantsLists={plantsLists}
          handleSorting={handleSorting}
        />
      )}
    </div>
  );
};

export default Centers;
