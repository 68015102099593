import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ListOrganisationUsers from './listOrganisationMembers';
import CreateOrganisationMember from './createOrganisationMember';
import EditOrganisationMember from './editOrganisationMember';

const Organisations = () => (
  <Routes>
    <Route path='*' element={<ListOrganisationUsers />} />
    <Route path='create' element={<CreateOrganisationMember />} />
    <Route path='edit/:userId' element={<EditOrganisationMember />} />
  </Routes>
);

export default Organisations;
