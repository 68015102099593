import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmailResetPwdForm from '../../components/form/EmailResetPwdForm';
import styles from './forgot-password.module.scss';
import { useForgotPasswordMutation } from '../../services/api/auth';

import ImgSignin from '../../assets/images/illu-signin.svg';

const ForgotPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const { t } = useTranslation('auth');
  const [forgotPassword] = useForgotPasswordMutation();
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');

  const submitForgot = async (data: { email: string }) => {
    try {
      setMessage('');
      setError('');
      const res = await forgotPassword(data).unwrap();
      console.log(res);
      setMessage(t('forgot.success'));
    } catch (err) {
      console.log(err);
      setError(t('forgot.emailError'));
    }
  };
  console.log(authReducer.error);
  return (
    <div className={styles.forgotPassword}>
      <div className={styles.container}>
        <div>
          <div className={styles.form}>
            <h1>Mot de passe oublié</h1>
              <EmailResetPwdForm
              submit={submitForgot}
              signinLink="/login"
              succeedMessage={message}
              errorMessage={error}
            />

          </div>
        </div>
        <div>
          <img src={ImgSignin} alt="illutration connexion" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
