import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { IPlantsList } from '../../types/plantsList';

import styles from './plantsLists-table.module.scss';
import Tags from '../Tags';

const PlantsListsTable = (
  {
    plantsLists,
    handleSorting,
  } : {
    plantsLists: IPlantsList[],
    handleSorting: any,
  },
) => {
  const { t } = useTranslation('plantsLists');

  const columns = [{
    header: t('columns.name'),
    accessorKey: 'name',
  }, {
    header: t('columns.date'),
    cell: (row: any) => (<span>{format(new Date(row.getValue()), 'dd/MM/yyyy')}</span>),
    accessorKey: 'createdAt',
  }, {
    header: t('columns.creator'),
    cell: (row: any) => (<span>{row.getValue()?.firstName} {row.getValue()?.lastName}</span>),
    accessorKey: 'creator',
  }, {
    header: t('columns.tags'),
    cell: (row: any) => <Tags tags={(row.getValue())}/>,
    accessorKey: 'tags',
  },
  {
    header: t('columns.count'),
    cell: (row: any) => (<span>{row.getValue().length}</span>),
    accessorKey: 'plants',
  },
  ];

  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => plantsLists, [plantsLists]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IPlantsList>) => {
    navigate(`/plants/detail/${row.original._id}`);
  };

  return (
    <div className={styles.PlantsLists}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <>
                    {typeof header.column.getIsSorted() === 'string' && (
                      {
                        asc: <IoChevronUpOutline />,
                        desc: <IoChevronDownOutline />,
                      }[header.column.getIsSorted() as string]
                    )}
                  </>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlantsListsTable;
