import React from 'react';
import {
  Routes, Route, NavLink, useParams, useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import Edit from './edit';
import OrganisationUsers from './organisationMembers';
import styles from './organisation-detail.module.scss';
import { useGetOrganisation } from '../../../hooks/organisations';

const Details = () => {
  const { t } = useTranslation(['global', 'organisations']);
  const { organisationId } = useParams();
  const { pathname } = useLocation();
  const {
    data: organisation,
  } = useGetOrganisation(organisationId);
  return (
    <div className={styles.detail}>
      <div className={styles.navigation}>
        <NavLink className={styles.backward} to="/organisations">
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
        <header>
          <div className={styles.title}>
            <label>{t('title', { ns: 'organisations' })}</label>
            <h1>{organisation?.name}</h1>
          </div>
          <div className={styles.links}>
            <NavLink to={`/organisations/detail/${organisationId}`}
              className={`${pathname.includes('/users') ? '' : styles.active}`}
            >
              {t('nav.informations', { ns: 'organisations' })}
            </NavLink>
            <NavLink
              to={`/organisations/detail/${organisationId}/users`}
              className={`${pathname.includes('/users') ? styles.active : ''}`}
            >
            {t('nav.members', { ns: 'organisations' })}
            </NavLink>
          </div>
        </header>
      </div>
      <Routes>
        <Route path='users/*' element={<OrganisationUsers />} />
        <Route path='*' element={<Edit />} />
      </Routes>
    </div>
  );
};

export default Details;
