import React, { useRef } from 'react';
// import { IoAdd } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateUser } from '../../../../../hooks/users';
import ClientForm from '../../../../../components/form/ClientForm';
import { ErrorField } from '../../../../../lib/HooksFormFields';
import styles from './create-organisation-member.module.scss';

const CreateOrganisationMember = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const { organisationId } = useParams();
  const formUserRef = useRef<any>();
  const {
    mutateAsync: createClient,
    isLoading: isPostLoading,
    error,
  } = useCreateUser();
  const createUserError: any = error;

  const handleSubmit = async () => {
    const data = await formUserRef.current.submit();
    if (!data) return;
    const { user }: any = await createClient({
      ...data,
      organisations: [organisationId],
    });
    if (user) navigate(`/organisations/detail/${organisationId}/users`);
  };

  return (
    <div className={styles.createMember}>
      <div className={styles.save}>
        <button onClick={handleSubmit}>
          {isPostLoading && <div className={styles.dotLoader} />}
          <span>{t('save')}</span>
        </button>
      </div>
      <div className={styles.form}>
        <ClientForm
          ref={formUserRef}
          organisationId={organisationId}
        />
          {createUserError?.response?.data?.error === 'That email address is already in use.' && (
          <ErrorField message={t('emailUsed', { ns: 'createUser' })} />
          )}
      </div>
    </div>
  );
};

export default CreateOrganisationMember;
