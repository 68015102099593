import React from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import { useGetUsers, useGetUsersList } from '../../../../../hooks/users';
import { useGetOrganisation } from '../../../../../hooks/organisations';
import InputSearch from '../../../../../components/InputSearch';
import UsersTable from '../../../../../components/UsersTable';
import FilterSearchParam from '../../../../../components/FilterSearchParam';
import styles from './list-organisation-members.module.scss';
import { IUser } from '../../../../../types/users';
import useOptionsLang from '../../../../../hooks/useOptionsLang';
import useTagsToOptionsLang from '../../../../../hooks/useTagsToOptionsLang';
import ButtonExpandable from '../../../../../components/ButtonExpandable';
// import useTagsToOptionsLang from '../../../../../hooks/useTagsToOptionsLang';

const ListOrganisationMembers = () => {
  const { t } = useTranslation('organisationUsers');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const debouncedSearch = useDebounce<string>(search, 500);
  const { organisationId } = useParams();
  const {
    data: users,
  } = useGetUsers({
    organisation: organisationId,
    search: debouncedSearch,
    ...paramsObject,
    role: paramsObject.role ? [paramsObject.role] : undefined,
  });
  const { data: list } = useGetUsersList();
  const { data: organisation } = useGetOrganisation(organisationId);
  const tags = useTagsToOptionsLang(organisation?.tags || []);

  const userRoles = useOptionsLang(list?.userRoles || []);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.listOrganisationMembers}>
      <div className={styles.header}>
        <h2>
          {`${(users || []).length} `}
          {t('title')}
          {(users || []).length > 0 && 's'}
        </h2>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            defaultValue={search}
          />
          <NavLink to="create">
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new-user')}
            />
          </NavLink>
        </div>
      </div>
      <div className={styles.filters}>
        <FilterSearchParam
          label={t('filters.roles')}
          name='role'
          options={userRoles}
        />
        <FilterSearchParam
          label={t('filters.tags')}
          name='tag'
          options={tags || []}
        />
      </div>
      {((users || []).length > 0) && (
        <UsersTable
          users={users as IUser[]}
          baseTo={`/organisations/detail/${organisationId}/users/edit`}
          handleSorting={handleSorting}
        />
      )}
    </div>
  );
};

export default ListOrganisationMembers;
