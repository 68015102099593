/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  createTag,
  getTag,
  getTags,
  updateTag,
} from '../../api/tags';
import {
  ITag,
  ArgsGetTags,
} from '../../types/tags';

const useGetTags = (params: ArgsGetTags) => useQuery({
  queryKey: ['tags', params],
  queryFn: async (): Promise<ITag[]> => getTags(params),
  keepPreviousData: true,
});

const useGetTag = (id?: string) => useQuery({
  queryKey: ['tags', id],
  queryFn: async (): Promise<ITag | null | undefined> => getTag(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useCreateTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ITag>) => createTag(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });
};

const useUpdateTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ITag>) => updateTag(data),
    onSuccess: async ({ tag }) => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      queryClient.setQueryData(['tags', tag._id], tag);
    },
  });
};

export {
  useGetTags,
  useGetTag,
  useCreateTag,
  useUpdateTag,
};
