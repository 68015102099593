import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITag } from '../../types/tags';
import styles from './tags.module.scss';

const Tags = ({ tags = [] }: { tags: ITag[] }) => {
  const { i18n } = useTranslation();
  const lang = i18n?.language || 'fr';
  return (
    <div className={styles.tags}>
      {tags?.filter((a: any, i: number) => i < 2).map(
        (a: any) => (<p key={a._id}
          className={styles.tag}
          style={{ backgroundColor: a.color }}
        >
        {a?.name && a?.name[lang]}
      </p>),
      )}
      {tags?.length > 2
        && <p
          className={styles.tag}
        >
          +{tags.length - 2}
        </p>
      }
    </div>

  );
};

export default Tags;
