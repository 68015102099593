/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  getOrganisation,
  getOrganisations,
  getOrganisationsList,
  createOrganisation,
  updateOrganisation,
} from '../../api/organisations';
import {
  IOrganisation,
  ArgsGetOrganisations,
} from '../../types/organisations';

const useGetOrganisations = (params: ArgsGetOrganisations) => useQuery({
  queryKey: ['Organisations', params],
  queryFn: async (): Promise<IOrganisation[] | null> => getOrganisations(params),
  keepPreviousData: true,
});

const useGetOrganisation = (id?: string) => useQuery({
  queryKey: ['Organisation', id],
  queryFn: async (): Promise<IOrganisation | null | undefined> => getOrganisation(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useGetOrganisationsList = () => useQuery({
  queryKey: ['OrganisationsList'],
  queryFn: async (): Promise<any | null> => getOrganisationsList(),
  keepPreviousData: true,
});

const useCreateOrganisation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IOrganisation>) => createOrganisation(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Organisations'] });
      queryClient.invalidateQueries({ queryKey: ['OrganisationsList'] });
    },
  });
};

const useUpdateOrganisation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IOrganisation>) => updateOrganisation(data),
    onSuccess: async ({ organisation }) => {
      queryClient.invalidateQueries({ queryKey: ['Organisations'] });
      queryClient.invalidateQueries({ queryKey: ['UsersList'] });
      queryClient.invalidateQueries({ queryKey: ['OrganisationsList'] });
      queryClient.setQueryData(['Organisation', organisation._id], organisation);
    },
  });
};

export {
  useGetOrganisations,
  useGetOrganisation,
  useGetOrganisationsList,
  useCreateOrganisation,
  useUpdateOrganisation,
};
