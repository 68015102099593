/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ITag } from '../../hooks/useTagsToOptionsLang';

import {
  ArgsGetTags, ArgsPostTag,
} from '../../types/tags';

const getTags = async (params: ArgsGetTags) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/tags`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.tags;
};

const getTag = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/tags/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.tag;
};

// eslint-disable-next-line max-len
const createTag = async (data: Partial<ArgsPostTag>): Promise<{ tag: ITag }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/tags`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

// eslint-disable-next-line max-len
const updateTag = async (
  data: Partial<ArgsPostTag>,
): Promise<{ tag: ITag }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/tags/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getTags,
  getTag,
  createTag,
  updateTag,
};
