import * as React from 'react';

const IconStat = ({ fill = '#949494', ...props } : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M5.899.665v11.67a.656.656 0 0 1-.793.649C2.176 12.322 0 9.669 0 6.5 0 3.33 2.176.678 5.106.016a.648.648 0 0 1 .73.363c.042.089.063.187.063.286Zm1.33 0V5.17a.66.66 0 0 0 .656.662h4.45c.42 0 .734-.39.643-.807C12.42 2.537 10.487.579 8.029.016a.658.658 0 0 0-.8.649Zm0 7.165v4.505c0 .424.387.741.8.649 2.465-.563 4.398-2.527 4.955-5.015a.667.667 0 0 0-.642-.807h-4.45a.672.672 0 0 0-.663.668Z"
    />
  </svg>
);

export default IconStat;
