/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-destructuring */
// @ts-nocheck
import React, {
  useMemo,
} from 'react';
import styles from './questionsDistribution.module.scss';
import DistributedInput from './DistributedInput';
import RangerComponent from './RangerComponent';

interface IQestionsDistribution {
  questionsType: string,
  idInput: string,
  totalQuestions: number,
  inputs?: {
    [key: string]: {
      value: number;
      // label: string;
      // range: string;
    }
  },
  labels?: {
    [key: string]: {
      label: string;
      range: string;
      name: string;
    }
  },
  setInputs?: React.Dispatch<React.SetStateAction>,
}

const notNegative = (number: number) => {
  if (number < 0) {
    return 0;
  }
  return number;
};

function QuestionsDistribution(
  {
    questionsType,
    idInput,
    totalQuestions,
    inputs,
    labels,
    setInputs,
  } :
  IQestionsDistribution,
) {
  function handleChangeRange(arr: any) {
    const obj: any = { ...inputs };
    const newEntries = Object.entries(obj);
    const rangeValueOfLastIndex = totalQuestions;
    newEntries.forEach(([key], i: any) => {
      obj[key].value = arr[i];
      if (i === newEntries.length - 1) {
        obj[key].rangeValue = rangeValueOfLastIndex;
      } else {
        obj[key].rangeValue = arr[i];
      }
      if (i === 0) {
        obj[key].value = arr[0];
      } else {
        obj[key].value = obj[key].rangeValue - newEntries[i - 1][1].rangeValue;
      }
    });
    setInputs(obj);
  }

  const handleInputChange = (key: string, newValue: number) => {
    const obj: any = { ...inputs };
    const inputKeys = Object.keys(obj);
    const currentIndex = inputKeys.indexOf(key);
    const nextIndex = (currentIndex + 1) % inputKeys.length;
    const currentValue = obj[key].value;
    const difference = newValue - currentValue;

    if (currentIndex === inputKeys.length - 1) {
      obj[key].value = newValue;
      obj[inputKeys[nextIndex]].value = notNegative(obj[inputKeys[nextIndex]].value - difference);
      obj[inputKeys[nextIndex]].rangeValue = notNegative(obj[inputKeys[nextIndex]]
        .rangeValue - difference);
    } else if (currentIndex === inputKeys.length - 2) {
      obj[key].value = newValue;
      obj[key].rangeValue = notNegative(obj[key].rangeValue + difference);
      obj[inputKeys[nextIndex]].value = notNegative(obj[inputKeys[nextIndex]].value - difference);
    } else {
      obj[key].value = newValue;
      obj[key].rangeValue = notNegative(obj[key].rangeValue + difference);
      obj[inputKeys[nextIndex]].value = notNegative(obj[inputKeys[nextIndex]].value - difference);
      obj[inputKeys[nextIndex]].rangeValue = notNegative(obj[inputKeys[nextIndex]].rangeValue - difference);
    }
    const currentTotal = inputKeys.reduce((sum, k) => sum + obj[k].value, 0);
    const values = inputKeys.map((k) => ({ key: k, ...obj[k] }));
    if (currentTotal > totalQuestions) {
      if (difference > 0) {
        const item = values.find((v: any) => v.value >= difference && v.key !== key);
        if (item.key) {
          if (newValue !== totalQuestions) {
            obj[item.key].value = obj[item.key].value - difference;
            obj[item.key].rangeValue = obj[item.key].rangeValue - difference;
          } else {
            obj[item.key].value = 0;
            obj[item.key].rangeValue = 0;
          }
        }
      }
    }
    return setInputs(obj);
  };

  const inputsWithRangeValue = useMemo(() => {
    if (!inputs) {
      return null;
    }

    const obj: any = { ...inputs };

    const newEntries = Object.entries(obj);
    let cumul = 0;

    newEntries.forEach(([key]) => {
      if (obj[key]?.value >= 0) {
        cumul += obj[key].value;
        obj[key].rangeValue = cumul;
      } else {
        console.log(obj[key]);
      }
    });

    return obj;
  }, [inputs]);

  if (idInput === 'qru_questions') {
    console.log(inputsWithRangeValue);
  }

  return (
    <div className={styles.questionsDistribution}>
      <div className={styles.inputsContainer}>
        <div className={styles.totalQuestionsInput}>
          <label htmlFor={idInput}>
            {questionsType}
            <input type="text" name={idInput} id={idInput} value={totalQuestions} readOnly />
          </label>
        </div>
        <div className={styles.distributedInputsContainer}>
          { inputs
      && Object.entries(inputs).map(([key, item]: any) => (
        <DistributedInput
          key={labels[key]?.label}
          label={labels[key]?.label}
          name={labels[key]?.name}
          value={item.value}
          onInputChange={(newValue) => handleInputChange(key, newValue)}
          totalQuestions={totalQuestions}
        />
      ))}
        </div>
      </div>
      {
        inputsWithRangeValue
      && (
        <RangerComponent
          total={totalQuestions}
          entries={Object.entries(inputsWithRangeValue).map(([key, value]) => ({
            ...value,
            range: labels[key].range,
            key,
          }))}
          handleChange={(array:any) => handleChangeRange(array)}
        />
      )
      }
    </div>
  );
}

export default QuestionsDistribution;
