import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ResetPwdForm from '../../components/form/ResetPwdForm';
import styles from './reset-password.module.scss';
import { useResetPasswordMutation } from '../../services/api/auth';

import ImgSignin from '../../assets/images/illu-signin.svg';

const ResetPassword = () => {
  const { token } = useParams();
  const authReducer = useSelector((d: any) => d.authReducer);
  const [resetPassword] = useResetPasswordMutation();

  const submitReset = async (data: { password: string }) => {
    if (!token) return;
    try {
      const res = await resetPassword({
        token,
        ...data,
      }).unwrap();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.resetPassword}>
      <div className={styles.container}>
        <div>
          <div className={styles.form}>
            <h1>Mot de passe oublié</h1>
            <ResetPwdForm
              submit={submitReset}
              // signinLink={null}
              succeedMessage={''}
              errorMessage={authReducer.error}
            />
          </div>
        </div>
        <div>
          <img src={ImgSignin} alt="illutration connexion" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
