import { useQuery } from '@tanstack/react-query';
import { ArgsGetStats } from '../../types/stats';
import { getDashboard, getStatsFormations, getStatsGlobal } from '../../api/stats';

const useGetDashboard = (params: ArgsGetStats) => useQuery({
  queryKey: ['Dashboard', params],
  queryFn: async (): Promise<any | null> => getDashboard(params),
  keepPreviousData: true,
});

const useGetStatsGlobal = (params: ArgsGetStats) => useQuery({
  queryKey: ['Stats', params],
  queryFn: async (): Promise<any | null> => getStatsGlobal(params),
  keepPreviousData: true,
});

const useGetStatsFormation = (params: ArgsGetStats) => useQuery({
  queryKey: ['Stats-formations', params],
  queryFn: async (): Promise<any | null> => getStatsFormations(params),
  keepPreviousData: true,
});

export {
  useGetDashboard,
  useGetStatsGlobal,
  useGetStatsFormation,
};
