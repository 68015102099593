/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  getFormations,
  getFormation,
  getFormationsList,
  createFormation,
  updateFormation,
  duplicateFormation,
  deleteFormation,
} from '../../api/formations';
import {
  IFormation,
  ArgsGetFormations,
} from '../../types/formations';

const useGetFormations = (params: ArgsGetFormations) => useQuery({
  queryKey: ['Formations', params],
  queryFn: async (): Promise<IFormation[] | null> => getFormations(params),
  keepPreviousData: true,
});

const useGetFormation = (id?: string) => useQuery({
  queryKey: ['Formations', id],
  queryFn: async (): Promise<IFormation | null | undefined> => getFormation(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useGetFormationsList = () => useQuery({
  queryKey: ['FormationsList'],
  queryFn: async (): Promise<any | null> => getFormationsList(),
  keepPreviousData: true,
});

const useCreateFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IFormation>) => createFormation(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
    },
  });
};

const useDuplicateFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => duplicateFormation(id),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
    },
  });
};

const useUpdateFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IFormation>) => updateFormation(data),
    onSuccess: async ({ formation }) => {
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
      queryClient.setQueryData(['Formations', formation._id], formation);
    },
  });
};

const useDeleteFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteFormation(id),
    onSuccess: async (_: any, variables: any) => {
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
      queryClient.setQueryData(['Formations', variables], null);
    },
  });
};

export {
  useGetFormations,
  useGetFormation,
  useGetFormationsList,
  useCreateFormation,
  useUpdateFormation,
  useDuplicateFormation,
  useDeleteFormation,
};
