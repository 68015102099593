import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { IoAdd } from 'react-icons/io5';
import { ArgsGetUsers } from '../../../types/users';
import { useGetUsers } from '../../../hooks/users';
import UsersTepikTable from '../../../components/UsersTepikTable';
import ButtonExpandable from '../../../components/ButtonExpandable';
import styles from './tepik-team.module.scss';
import InputSearch from '../../../components/InputSearch';

const TepikTeam = () => {
  const { t } = useTranslation('tepikTeam');
  const [params, setParams] = useState<Partial<ArgsGetUsers>>({});
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce<string>(search || '', 500);
  const { data: users = [], isLoading } = useGetUsers({
    search: debouncedSearch,
    role: ['Admin'],
    ...params,
  });

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const updateParams = {
      ...params,
      sortKey: sort.id.replace('_', '.'),
      order: sort.desc ? 'desc' : 'asc',
    };
    setParams(updateParams);
  };

  const handleSearch = (searchValue: string) => {
    if (searchValue.length < 3) setSearch('');
    setSearch(searchValue);
  };

  return (
    <div className={styles.tepikTeam}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          {users.length > 0 && <span>{users.length} {t('members')}{users.length > 1 && 's'}</span>}
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            isToggle
          />
          <NavLink to="/users/create-admin">
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new-member')}
            />
          </NavLink>
        </div>
      </div>
      {isLoading && <p>Loading</p>}
      {users.length > 0 && (
        <UsersTepikTable
          users={users}
          handleSorting={handleSorting}
        />
      )}
    </div>
  );
};

export default TepikTeam;
