import React from 'react';
import {
  Routes, Route, NavLink, useParams, useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import {
  useGetCenter,
} from '../../../hooks/centers';
import Edit from '../edit';

import styles from './center-detail.module.scss';
import Sessions from '../sessions';

const Details = () => {
  const { t } = useTranslation(['global', 'createCenter']);
  const { id: centerId } = useParams();
  const { pathname } = useLocation();
  const {
    data: center,
  } = useGetCenter(centerId);
  return (
    <div className={styles.detail}>
      <div className={styles.navigation}>
        <NavLink className={styles.backward} to="/centers">
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
        <header>
          <div className={styles.title}>
            <label>{t('label', { ns: 'createCenter' })}</label>
            <h1>{center?.name}</h1>
          </div>
          <div className={styles.links}>
            <NavLink to={`/centers/detail/${centerId}`}
              className={`${pathname.includes('/sessions') ? '' : styles.active}`}
            >
              {t('nav.infos', { ns: 'createCenter' })}
            </NavLink>
            <NavLink
              to={`/centers/detail/${centerId}/session`}
              className={`${pathname.includes('/sessions') ? styles.active : ''}`}
            >
            {t('nav.session', { ns: 'createCenter' })}
            </NavLink>
          </div>
        </header>
      </div>
      <Routes>
        <Route path='session/*' element={<Sessions />} />
        <Route path='*' element={<Edit />} />
      </Routes>
    </div>
  );
};

export default Details;
