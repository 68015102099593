import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { InputText } from '../../lib/HooksFormFields';
import styles from './input-search.module.scss';

const InputSearch = (
  { defaultValue = '', handleSearch, isToggle = true } : { defaultValue?: string, handleSearch : (str: string) => void, isToggle?: boolean },
) => {
  const [isOpen, setIsOpen] = useState(!!defaultValue);
  const { control } = useForm({
    defaultValues: { search: defaultValue },
  });
  return (
    <div className={styles.inputSearch}>
      {!isToggle && <InputText
          name='search'
          control={control as any}
          handleChange={(value) => handleSearch(value)}
        />
      }
       {isToggle && isOpen && <InputText
          name='search'
          control={control as any}
          handleChange={(value) => handleSearch(value)}
        />
      }
      {isToggle && !isOpen
        && <button
          type='button'
          className={styles.toggle}
          onClick={() => setIsOpen(true)}
        >
          <FiSearch />
        </button>
      }
    </div>
  );
};

export default InputSearch;
