import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { GoKebabHorizontal } from 'react-icons/go';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IFormation } from '../../types/formations';
import { useDeleteFormation, useDuplicateFormation } from '../../hooks/formations';

import styles from './formations-table.module.scss';
import Tags from '../Tags';

const Dropdown = ({ id }:{ id: string }) => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: deleteFormation, error: errorDelete } = useDeleteFormation();

  console.log(errorDelete);

  const {
    mutateAsync: duplicateFormation,
  } = useDuplicateFormation();

  function handleEdit(e :any) {
    e.stopPropagation();
    navigate(`/formation/edit/${id}`);
  }
  async function handleDuplicate(e :any) {
    e.stopPropagation();
    await duplicateFormation(id);
    setIsOpen(!isOpen);
  }
  async function handleDelete(e :any) {
    e.stopPropagation();
    await deleteFormation(id);
    setIsOpen(!isOpen);
  }
  function handleIsOpen(e :any) {
    e.stopPropagation();
    setIsOpen(!isOpen);
  }

  return (<div className={styles.dropdown}>
    <div
      className={styles.icon}
      onClick={(e) => handleIsOpen(e)}
    >
      <GoKebabHorizontal />
    </div>
    <div className={`${styles['dropdown-content']} ${isOpen ? styles.open : ''}`}>
      <div className={styles.content} onMouseLeave={() => setIsOpen(false)}>
        <div onClick={(e) => handleEdit(e)} className={styles.item}>
          <p>{t('edit')}</p>
        </div>
        <div onClick={(e) => handleDuplicate(e)} className={styles.item}>
          <p>{t('duplicate')}</p>
        </div>
        <div onClick={(e) => handleDelete(e)} className={styles.item}>
          <p>{t('delete')}</p>
        </div>
      </div>
    </div>
  </div>);
};

const FormationsTable = (
  {
    formations,
    handleSorting,
  } : {
    formations: IFormation[],
    handleSorting: any,
  },
) => {
  const { t } = useTranslation('formations');

  const columns = [{
    header: t('columns.name'),
    accessorKey: 'name',
  }, {
    header: t('columns.count'),
    cell: (row: any) => (<span>{row.getValue()?.length}</span>),
    accessorKey: 'quiz',
  }, {
    header: t('columns.tags'),
    cell: (row: any) => <Tags tags={(row.getValue())}/>,
    accessorKey: 'tags',
  }, {
    header: t('columns.state'),
    cell: (row: any) => (<div className={styles.status}>
      <p className={`${styles.statusLabel} ${row.getValue() ? styles.close : ''}`}>{row.getValue() ? 'Terminé' : 'En cours' }</p>
      <Dropdown id={row?.row?.original?._id} />
    </div>),
    accessorKey: 'isClose',
  }];

  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => formations, [formations]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (e: any, row: Row<IFormation>) => {
    e.preventDefault();
    navigate(`/formation/detail/${row.original._id}`);
  };

  return (
    <div className={styles.formationsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={
                    !['type', 'tags'].includes(header.id)
                      ? header.column.getToggleSortingHandler()
                      : () => {}
                  }
                >
                  {!['type', 'tags'].includes(header.id) && (
                    <>
                      {typeof header.column.getIsSorted() === 'string' ? (
                        {
                          asc: <IoChevronUpOutline />,
                          desc: <IoChevronDownOutline />,
                        }[header.column.getIsSorted() as string]
                      ) : (
                        <span className={styles.sortIcons}>
                          <IoChevronUpOutline />
                          <IoChevronDownOutline />
                        </span>
                      )}
                    </>
                  )}
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={(e) => onRowClick(e, row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormationsTable;
