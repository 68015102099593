import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';
import EditTag from './edit';

const Tags = () => (
  <Routes>
    <Route path='edit/:id' element={<EditTag />} />
    <Route path='*' element={<List />} />
  </Routes>
);

export default Tags;
