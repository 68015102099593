import React, { useEffect } from 'react';
import {
  NavLink, useParams, useLocation,
} from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { ErrorField } from '../../../../lib/HooksFormFields';
import {
  useGetQuizQuery,
} from '../../../../services/api/quiz';
import Loader from '../../../../components/Loader';
import styles from './preview-mode.module.scss';
import { IQuestion } from '../../../../types/questions';
import Question from '../../../../components/Question';

const PreviewMode = () => {
  const location = useLocation();
  const params = useParams();
  const { id } = params;
  const { data: quiz, isLoading: isGetLoading, error: errorGet } = useGetQuizQuery(id || skipToken);
  const errorRequest: any = errorGet;

  useEffect(() => {
    console.log('quiz', quiz);
  }, [quiz]);

  if (isGetLoading || !quiz) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.preview}>
      <div className={styles.questions}>
        {quiz.questions.map((question: IQuestion, i: number) => (
          <Question
            key={`question-${question._id}`}
            question={question}
            index={i + 1}
          />
        ))}
        {errorRequest?.message && (
          <ErrorField message={errorRequest.message} />
        )}
      </div>
      <div className={styles.moreSettings}>
        <div className={styles.switchMode}>
          <NavLink
            to={`/quiz/edit/${id}`}
            className={() => (location.pathname === `/quiz/edit/${id}`
              ? styles.actived
              : '')
          }
          >
            Mode édition
          </NavLink>
          <NavLink
            to={`/quiz/edit/${id}/preview`}
            className={() => (location.pathname === `/quiz/edit/${id}/preview` ? styles.actived : '')}
          >
            Mode aperçu
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PreviewMode;
