import * as React from 'react';

const IconHome = ({ fill = '#949494', ...props } : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M10.008 6.069a.5.5 0 0 0-.707-.021C6.998 8.217 4.687 9.537 1.968 10.21a5.04 5.04 0 0 1-.083-3.177c.42-1.426 1.4-2.669 2.83-3.593 1.278-.825 2.555-1.04 3.79-1.248 1.558-.262 3.03-.51 4.435-1.997a.58.58 0 0 1 .573-.177c.232.063.388.247.463.55.175.712.063 2.707-.43 4.812-.79 3.382-2.053 5.094-2.973 5.934C9.384 12.4 7.833 13 6.29 13c-.29 0-.581-.02-.869-.064-1.304-.197-2.39-.853-3.002-1.806 2.799-.728 5.19-2.113 7.569-4.353a.5.5 0 0 0 .02-.708ZM.435 10.516a17.342 17.342 0 0 0 1.532-.305 4.933 4.933 0 0 0 .451.919c-.603.156-1.215.281-1.831.375a.5.5 0 0 1-.34-.925.5.5 0 0 1 .188-.064Z"
    />
  </svg>
);

export default IconHome;
