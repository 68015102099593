/* eslint-disable import/no-named-as-default */
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import { useParams, useSearchParams } from 'react-router-dom';

import ButtonExpandable from '../../../../components/ButtonExpandable';
import FilterSearchParam from '../../../../components/FilterSearchParam';
import LearnersTable from '../../../../components/LearnersTable';
// import FormationsUsersModal from '../../../../components/FormationsUsersModal';
// import LearnersTable from '../../../../components/LearnersTable';
import ShareModal from '../../../../components/ShareModal';
import { useGetFormation, useUpdateFormation } from '../../../../hooks/formations';

import useTagsToOptionsLang, { ITag } from '../../../../hooks/useTagsToOptionsLang';
import Modal, { ModalRefProps } from '../../../../lib/Modal';
import { useGetListQuizQuery } from '../../../../services/api/quiz';
import { IOrganisation } from '../../../../types/organisations';
import { IUser } from '../../../../types/users';
import styles from './learners.module.scss';

const Learners = () => {
  const { formationId } = useParams();
  const [searchParams] = useSearchParams();

  const shareRef = useRef<ModalRefProps | null>(null);
  const { t } = useTranslation(['global', 'formations']);
  // const navigate = useNavigate();
  const {
    data: formation,
  } = useGetFormation(formationId);
  const { data: list } = useGetListQuizQuery();

  const {
    isLoading,
    mutateAsync: updateFormation,
  } = useUpdateFormation();

  const tags = useTagsToOptionsLang(list?.tags || []);

  const organisationsList = list?.organisations || [];
  console.log(list);

  const learners = formation?.learners?.map((l) => l?._id) || [];
  const organisations = formation?.organisations?.map((l: any) => l?._id) || [];

  const tag = searchParams.get('tag');
  const organistion = searchParams.get('organistion');

  const learnersFiltered = useMemo(() => formation?.learners.filter((u: IUser) => {
    console.log(u);
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (organistion && !(u.organisations as any)?.find(
      (o: ITag) => o._id === organistion,
    )) {
      return null;
    }
    return u;
  }), [formation?.learners, organistion, tag]) || [];

  const organistionsFiltered = useMemo(() => formation?.organisations.filter((u: IOrganisation) => {
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (organistion && u._id !== organistion) {
      return null;
    }
    return u;
  }), [formation?.organisations, organistion, tag]) || [];

  return (
    <div className={styles.learners}>
      <div className={styles.header}>
        <div className={styles.filters}>
          <FilterSearchParam
            label={t('filters.organisation', { ns: 'formations' })}
            name='organistion'
            options={organisationsList}
          />
          <FilterSearchParam
            label={t('filters.tags', { ns: 'formations' })}
            name='tag'
            options={tags || []}
          />
        </div>
        <div
          onClick={() => shareRef?.current?.open()}
        >
          <ButtonExpandable
            icon={<IoAdd size={24} />}
            text={t('users.add', { ns: 'formations' })}
          />
        </div>
      </div>
      <LearnersTable
        users={[
          ...organistionsFiltered,
          ...learnersFiltered.map((l :any) => ({ ...l, name: `${l.profile.firstName} ${l.profile.lastName}` })),
        ]}
      />
      <Modal
        ref={shareRef}
      >
        <ShareModal
          update={(obj: any) => updateFormation({ _id: formation?._id, ...obj })}
          learners={learners || []}
          organisations={organisations || []}
          list={list}
          isLoading={isLoading}
          close={() => shareRef.current?.close()}
        />
      </Modal>
    </div>
  );
};

export default Learners;
