/* eslint-disable import/no-named-as-default */
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import { useParams, useSearchParams } from 'react-router-dom';

import ButtonExpandable from '../../../../components/ButtonExpandable';
import FilterSearchParam from '../../../../components/FilterSearchParam';
import LearnersTable from '../../../../components/LearnersTable';

import useTagsToOptionsLang, { ITag } from '../../../../hooks/useTagsToOptionsLang';
import Modal, { ModalRefProps } from '../../../../lib/Modal';

import styles from './learners.module.scss';
import { useGetSession, useGetSessionsList, useUpdateSession } from '../../../../hooks/session';
import { SessionLearner } from '../../../../types/sessions';
import SessionLearnersModale from '../../../../components/SessionLearnersModale';
import { IOrganisation } from '../../../../types/organisations';

const Learners = () => {
  const { sessionId } = useParams();
  const [searchParams] = useSearchParams();

  const shareRef = useRef<ModalRefProps | null>(null);
  const { t } = useTranslation(['global', 'session']);
  // const navigate = useNavigate();
  const {
    data: session,
  } = useGetSession(sessionId);
  const { data: list } = useGetSessionsList();

  const {
    mutateAsync: updateSession,
  } = useUpdateSession();

  const tags = useTagsToOptionsLang(list?.tags || []);

  const organisationsList = list?.organisations.map(
    (o: IOrganisation) => ({ value: o._id, label: o.name }),
  ) || [];

  const learners = session?.learners || [];

  const tag = searchParams.get('tag');
  const organistion = searchParams.get('organistion');

  const learnersFiltered = useMemo(() => session?.learners.filter((u: SessionLearner) => {
    if (tag && !(u.user.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (organistion && !(u.user.organisations as any)?.find(
      (o: ITag) => o._id === organistion,
    )) {
      return null;
    }
    return u;
  }), [session?.learners, organistion, tag]) || [];

  const deleteSessionUser = (id: string) => {
    const upadtedLearners = session?.learners
      .filter((l: SessionLearner) => l._id !== id)
      .map((l:SessionLearner) => ({
        user: l.user?._id,
        userId: l.userId,
      }));

    updateSession({ _id: session?._id, learners: upadtedLearners });
  };

  return (
    <div className={styles.learners}>
      <div className={styles.header}>
        <div className={styles.filters}>
          <FilterSearchParam
            label={t('filters.organisation', { ns: 'formations' })}
            name='organistion'
            options={organisationsList}
          />
          <FilterSearchParam
            label={t('filters.tags', { ns: 'formations' })}
            name='tag'
            options={tags || []}
          />
        </div>
        <div
          onClick={() => shareRef?.current?.open()}
        >
          <ButtonExpandable
            icon={<IoAdd size={24} />}
            text={t('users.add', { ns: 'sessions' })}
          />
        </div>
      </div>
      <LearnersTable
        isSession
        users={[
          ...learnersFiltered.map((l :any) => ({
            ...l,
            organisations: l.user.organisations,
            tags: l.user.tags,
            name: `${l.user.profile.firstName} ${l.user.profile.lastName}`,
          })),
        ]}
        deleteSessionUser={deleteSessionUser}
      />
      <Modal
        ref={shareRef}
      >
        <SessionLearnersModale
          update={(obj: any) => updateSession({ _id: session?._id, ...obj })}
          learners={learners || []}
          list={list}
          close={() => shareRef.current?.close()}
        />
      </Modal>
    </div>
  );
};

export default Learners;
