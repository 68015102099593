import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { IUser } from '../../types/users';

import styles from './users-table.module.scss';
import { useGetUsersList } from '../../hooks/users';
import useOptionsLang from '../../hooks/useOptionsLang';
import { IOption } from '../../types/options';
import Tags from '../Tags';

const UsersTable = (
  {
    users,
    handleSorting,
    baseTo = '/users/edit',
  } : {
    users: IUser[],
    handleSorting?: any,
    baseTo?: string,
  },
) => {
  const { t } = useTranslation('organisationUsers');

  const columns = [{
    header: t('columns.lastName'),
    accessorKey: 'profile.lastName',
  }, {
    header: t('columns.firstName'),
    accessorKey: 'profile.firstName',
  }, {
    header: t('columns.tags'),
    cell: (row: any) => <Tags tags={(row.getValue())}/>,
    accessorKey: 'tags',
  }, {
    header: t('columns.roles'),
    cell: (row: any) => row.value,
    accessorKey: 'role',
  }, {
    header: t('columns.createdAt'),
    cell: (row: any) => {
      const date = row.getValue();
      if (!date) return '';
      return format(date, 'dd.MM.yyyy', { locale: fr });
    },
    accessorKey: 'createdAt',
  }];

  const { data: list } = useGetUsersList();
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  const userRoles = useOptionsLang(list?.userRoles || []);

  useEffect(() => {
    if (typeof handleSorting === 'function') handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => users, [users]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IUser>) => {
    navigate(`${baseTo}/${row.original._id}`);
  };

  const getUserRoleLabel = useCallback((role: any) => (
    (userRoles || []).find((opt: IOption) => opt.value === role)?.label
  ), [userRoles]);

  return (
    <div className={styles.usersTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={
                    header.id !== 'tags'
                      ? header.column.getToggleSortingHandler()
                      : () => {}
                  }
                >
                  {header.id !== 'tags' && (
                    <>
                      {typeof header.column.getIsSorted() === 'string' ? (
                        {
                          asc: <IoChevronUpOutline />,
                          desc: <IoChevronDownOutline />,
                        }[header.column.getIsSorted() as string]
                      ) : (
                        <span className={styles.sortIcons}>
                          <IoChevronUpOutline />
                          <IoChevronDownOutline />
                        </span>
                      )}
                    </>
                  )}
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === 'role' ? (
                    <>
                      {flexRender(
                        cell.column.columnDef.cell,
                        {
                          ...cell.getContext(),
                          value: getUserRoleLabel(cell.getValue() || ''),
                        },
                      )}
                    </>
                  ) : (
                    <>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
