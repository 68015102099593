/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  getCenter, getCenters, getCentersList, createCenter, updateCenter,
} from '../../api/centers';
import {
  ICenter,
  ArgsGetCenters,
} from '../../types/centers';

const useGetCenters = (params: ArgsGetCenters) => useQuery({
  queryKey: ['Centers', params],
  queryFn: async (): Promise<ICenter[] | null> => getCenters(params),
  keepPreviousData: true,
});

const useGetCenter = (id?: string) => useQuery({
  queryKey: ['Center', id],
  queryFn: async (): Promise<ICenter | null | undefined> => getCenter(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useGetCentersList = () => useQuery({
  queryKey: ['Centers-list'],
  queryFn: async (): Promise<any | null> => getCentersList(),
  keepPreviousData: true,
});

const useCreateCenter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ICenter>) => createCenter(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Centers'] });
    },
  });
};

const useUpdateCenter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ICenter>) => updateCenter(data),
    onSuccess: async ({ examCenter }) => {
      queryClient.invalidateQueries({ queryKey: ['Centers'] });
      queryClient.setQueryData(['Center', examCenter._id], examCenter);
    },
  });
};

export {
  useGetCenters,
  useGetCenter,
  useGetCentersList,
  useCreateCenter,
  useUpdateCenter,
};
