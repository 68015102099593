import React from 'react';
import styles from './counter.module.scss';
import IconMembers from '../NavBar/Icons/IconMembers';
import IconOrganisations from '../NavBar/Icons/IconOrganisations';
import IconQuiz from '../NavBar/Icons/IconQuiz';

const Counter = ({
  count,
  newCount = 0,
  label,
  icon,
}: {
  count:number,
  newCount?: number,
  label:string,
  icon: string,
}) => (
    <div className={styles.counter}>
       <div className={styles.label}>
          <div className={styles.icon}>
            {icon === 'user' && <IconMembers />}
            {icon === 'organisations' && <IconOrganisations />}
            {icon === 'quiz' && <IconQuiz />}
          </div>
          <p>{label}</p>
       </div>
       <div className={styles.values}>
        <h2>{count}</h2>
        {newCount > 0 && <h4>+{newCount}</h4>}
       </div>
    </div>
);

export default Counter;
