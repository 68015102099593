import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetOrganisation, useUpdateOrganisation } from '../../../../hooks/organisations';
import OrganisationForm from '../../../../components/form/OrganisationForm';
import { ErrorField } from '../../../../lib/HooksFormFields';
import styles from './edit-organisation.module.scss';

const editOrganisation = () => {
  const { t } = useTranslation(['global', 'createOrganisation']);
  const { organisationId } = useParams();
  const navigate = useNavigate();
  const formOrganisationRef = useRef<any>();
  const {
    mutateAsync: updateOrganisation,
    isLoading: isPostLoading,
    error,
  } = useUpdateOrganisation();
  const updateOrganisationError: any = error;
  const {
    data: organisation, isLoading: isGetLoading,
  } = useGetOrganisation(organisationId);

  const handleSubmit = async () => {
    const data = await formOrganisationRef.current.submit();
    if (!data) return;
    const { organisation: updatedOrganisation }: any = await updateOrganisation({
      _id: organisation?._id,
      ...data,
    });
    if (updatedOrganisation) navigate('/organisations');
  };

  return (
    <div className={styles.editOrganisation}>
      <div className={styles.header}>
        <h2>{t('title', { ns: 'createOrganisation' })}</h2>
        <div>
          <button disabled={isPostLoading} onClick={handleSubmit}>
            {isPostLoading && <div className={styles.dotLoader} />}
            <span>{t('save', { ns: 'global' })}</span>
          </button>
        </div>
      </div>
      <div className={styles.content}>
        {isGetLoading && <p>Loading</p>}
        {organisation && (
          <>
            <OrganisationForm ref={formOrganisationRef} organisation={organisation} />
          </>
        )}
        {updateOrganisationError?.message && (
          <ErrorField message={updateOrganisationError.message} />
        )}
      </div>
    </div>
  );
};

export default editOrganisation;
