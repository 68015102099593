import React from 'react';
import styles from './loader.module.scss';

const Loader = (
  { isWhite = false, small = false }
  : { isWhite?: Boolean, small?: Boolean },
) => (
  <div
    className={`${styles.loader}${isWhite ? ` ${styles.white}` : ''}${small ? ` ${styles.small}` : ''}`}
  >
    <div /><div /><div /><div />
  </div>
);

export default Loader;
