import React from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import { useCreateTag } from '../../../hooks/tags';
import { ErrorField, InputText } from '../../../lib/HooksFormFields';
import styles from './createTag.module.scss';

const defaultValues : any = {
  color: '#B3DBCF',
};

export default function CreateTag() {
  const { t } = useTranslation(['tags']);
  const {
    mutateAsync: createTag,
  } = useCreateTag();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues,
  });

  async function onSubmit(values: any) {
    const data = { ...values, type: 'global' };
    data.name.en = values.name.fr;
    await createTag(data);
    reset(defaultValues);
  }
  const error = (errors as any)?.name?.fr?.message;
  return (
    <div className={styles.container}>
      <label>{t('newButton')}</label>
      <div className={styles.form}>
        <div className={styles.field}>
          <InputText
            name='name.fr'
            control={control}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
        </div>
        <div className={styles.color}>
          <div className={styles.select}
            style={{ backgroundColor: watch('color') }}
          >

          </div>
          <Controller
            name="color"
            control={control}
            defaultValue="#B3DBCF"
            render={({ field }: any) => (
              <input
                type="color"
                id="color"
                {...field}
              />
            )}
            />
        </div>
        <button
          type='button'
          onClick={handleSubmit(onSubmit)}
        >
          <IoAdd size={24} />
          <p>{t('newButton')}</p>
        </button >
      </div>
      {error && <ErrorField message={error} />}
    </div>
  );
}
