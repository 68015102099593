import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ICenter } from '../../types/centers';

import styles from './centers-table.module.scss';

const CentersTable = (
  {
    centers,
    handleSorting,
  } : {
    centers: ICenter[],
    handleSorting: any,
  },
) => {
  const { t } = useTranslation('centers');

  const columns = [{
    header: t('columns.centerName'),
    accessorKey: 'name',
  }, {
    header: t('columns.region'),
    accessorKey: 'address.region',
  }, {
    header: t('columns.capacity'),
    accessorKey: 'capacity',
  }];

  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => centers, [centers]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<ICenter>) => {
    navigate(`/centers/detail/${row.original._id}`);
  };

  return (
    <div className={styles.centersTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <>
                    {typeof header.column.getIsSorted() === 'string' && (
                      {
                        asc: <IoChevronUpOutline />,
                        desc: <IoChevronDownOutline />,
                      }[header.column.getIsSorted() as string]
                    )}
                  </>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CentersTable;
