import { skipToken } from '@reduxjs/toolkit/query';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Modal, { ModalRefProps } from '../../../../lib/Modal';
import { useGetListQuizQuery, useGetQuizQuery, useUpdateQuizMutation } from '../../../../services/api/quiz';
import ShareModal from '../../../ShareModal';
import styles from './share-settings.module.scss';

const ShareQuizSettings = () => {
  const { t } = useTranslation('global');
  const shareRef = useRef<ModalRefProps | null>(null);
  const params = useParams();
  const { id } = params;
  const { data: quiz } = useGetQuizQuery(id || skipToken);
  const { data: list = {} } = useGetListQuizQuery();
  const [updateQuiz, { isLoading }] = useUpdateQuizMutation();

  const learners = quiz?.learners?.map((l: string) => l) || [];
  const organisations = quiz?.organisations?.map((l: string) => l) || [];
  if (!quiz?._id) {
    return null;
  }
  return (<>
    <div className={styles.block}>
      <h4>{t('share.title')}</h4>
      <button
        type='button'
        onClick={() => shareRef?.current?.open()}
      >
        {t('share.quiz')}
      </button>
      <div className={styles.shared}>
        {learners.length > 0 && <p><span>{learners.length}</span> {t('share.users')}</p>}
        {organisations.length > 0 && <p><span>{organisations.length}</span> {t('share.organisations')}</p>}
      </div>
    </div>
    <Modal
      ref={shareRef}
    >
      <ShareModal
        update={(obj: any) => updateQuiz({ _id: quiz?._id, ...obj })}
        learners={learners}
        organisations={organisations}
        list={list}
        isLoading={isLoading}
        close={() => shareRef.current?.close()}
      />
    </Modal>
  </>);
};

export default ShareQuizSettings;
