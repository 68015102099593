import React, { useRef } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
// import { IoAdd } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useCreateUser } from '../../../hooks/users';
import AdminForm from '../../../components/form/AdminForm';
import { ErrorField } from '../../../lib/HooksFormFields';
import styles from './create-admin.module.scss';

const CreateAdmin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('createUser');
  const formUserRef = useRef<any>();

  const {
    mutateAsync: createUser,
    isLoading: isPostLoading,
    error,
  } = useCreateUser();
  const createUserError: any = error;

  const handleSubmit = async () => {
    const data = await formUserRef.current.submit();
    if (!data) return;
    const { user } : any = await createUser(data);
    if (user) navigate('/users/tepik-team');
  };

  return (
    <div className={styles.createAdmin}>
      <div className={styles.header}>
        <NavLink className={styles.backward} to='/users/tepik-team'>
          <FaArrowLeft />
        </NavLink>
        <button disabled={isPostLoading} onClick={handleSubmit}>
          {isPostLoading && <div className={styles.dotLoader} />}
          <span>Enregistrer</span>
        </button>
      </div>
      <div className={styles.form}>
        <AdminForm ref={formUserRef} />
        {createUserError?.response?.data?.error === 'That email address is already in use.' && (
          <ErrorField message={t('emailUsed')} />
        )}
      </div>
    </div>
  );
};

export default CreateAdmin;
