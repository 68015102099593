import React from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import Edit from '../edit';
import styles from './create-plants-list.module.scss';

const CreatePlantsList = () => {
  const { t } = useTranslation(['global']);
  const [searchParams] = useSearchParams();
  const queryUrl = new URLSearchParams(searchParams);
  const quizId = queryUrl.get('quiz');

  const backUrl = quizId ? `/quiz/edit/${quizId}` : '/plants';

  return (
    <div className={styles.create}>
      <div className={styles.header}>
        <NavLink className={styles.backward} to={backUrl}>
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
      </div>
      <Edit />
    </div>
  );
};

export default CreatePlantsList;
