/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  createArticle, deleteArticle, getArticleById, getArticles, getArticlesList, updateArticle,
} from '../../api/articles';

import { ArgsGetArticles, IArticle } from '../../types/article';

const useGetArticlesList = () => useQuery({
  queryKey: ['ArticlesList'],
  queryFn: async (): Promise<any | null> => getArticlesList(),
  keepPreviousData: true,
});

const useGetArticles = (params: ArgsGetArticles) => useQuery({
  queryKey: ['Articles', params],
  queryFn: async (): Promise<any | null> => getArticles(params),
  keepPreviousData: true,
});

const useGetArticleById = (id?: string) => useQuery({
  queryKey: ['Article', id],
  queryFn: async (): Promise<IArticle | null | undefined> => getArticleById(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useCreateArticle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IArticle>) => createArticle(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Articles'] });
    },
  });
};

const useUpdateArticle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IArticle>) => updateArticle(data),
    onSuccess: async ({ article }) => {
      queryClient.invalidateQueries({ queryKey: ['Articles'] });
      queryClient.invalidateQueries({ queryKey: ['Article'] });
      queryClient.setQueryData(['Article', article._id], article);
    },
  });
};

const useDeleteArticle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteArticle(id),
    onSuccess: async (_: any, variables: any) => {
      queryClient.invalidateQueries({ queryKey: ['Articles'] });
      queryClient.setQueryData(['Article', variables], null);
    },
  });
};

export {
  useGetArticles,
  useGetArticleById,
  useUpdateArticle,
  useCreateArticle,
  useGetArticlesList,
  useDeleteArticle,
};
