/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  IOrganisation,
  ArgsGetOrganisations,
} from '../../types/organisations';

const getOrganisations = async (params: ArgsGetOrganisations) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/organisations`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.organisations;
};

const getOrganisation = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/organisations/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.organisation;
};

const getOrganisationsList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/organisations/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

// eslint-disable-next-line max-len
const createOrganisation = async (data: Partial<IOrganisation>): Promise<{ organisation: IOrganisation }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/organisations`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

// eslint-disable-next-line max-len
const updateOrganisation = async (data: Partial<IOrganisation>): Promise<{ organisation: IOrganisation }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/organisations/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getOrganisations,
  getOrganisation,
  getOrganisationsList,
  createOrganisation,
  updateOrganisation,
};
