import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';

import EditArticle from './EditArticle';

const Articles = () => (
  <Routes>
    <Route path='create' element={<EditArticle />} />
    <Route path='edit/:id' element={<EditArticle />} />
    <Route path='*' element={<List />} />
  </Routes>
);

export default Articles;
