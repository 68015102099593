import React, { useMemo } from 'react';
import Select from 'react-select';
import selectStyles from './styles';
import styles from './select.module.scss';

type SelectProps = {
  label: string,
  defaultValue?: number | null,
  options: { label: string, value: string | number }[],
};

const SelectComponent = (
  {
    label,
    defaultValue = null,
    options,
  } : SelectProps,
) => {
  const defaultOption = useMemo(() => {
    if (defaultValue === null) return null;
    return options.find((d: { label: string, value: string | number }) => d.value === defaultValue);
  }, [options, defaultValue]);

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <Select
        options={options}
        styles={selectStyles}
        onChange={(opt) => {
          console.log(opt);
        }}
        defaultValue={defaultOption}
        isSearchable={false}
      />
    </div>
  );
};

export default SelectComponent;
