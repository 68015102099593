import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGetDashboard } from '../../hooks/stats';
import styles from './home.module.scss';
import Counter from '../../components/Counter';
import CustomLineChart from '../../components/LineChart';
import { IArticle } from '../../types/article';
import { ArcticleCard } from '../articles/list';
import FilterSearchParam from '../../components/FilterSearchParam';
import { getLast12Months } from '../../utils';

const Home = () => {
  const authReducer = useSelector((state: any) => state.authReducer);
  const { t, i18n } = useTranslation('stats');
  const { user } = authReducer;
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);
  const { data: dashboard } = useGetDashboard({
    ...paramsObject,
  });

  const lang = i18n?.language || 'fr';

  const lastMonth = getLast12Months(lang);

  useEffect(() => {
    if (!paramsObject.date) {
      setSearchParams({ date: lastMonth[0].value });
    }
  }, [lastMonth]);

  return (
    <div className={styles.home}>
      {dashboard
        && <>
          <header>
            <h1>Hello {user?.profile?.firstName}!</h1>
            <div className={styles.filter}>
              <FilterSearchParam
                name='date'
                options={lastMonth}
                notAll={true}
              />
            </div>
          </header>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.indicators}>
                <Counter
                  newCount={dashboard.learners?.new}
                  count={dashboard.learners?.count}
                  label={t('learners')}
                  icon='user'
                />
                <Counter
                  newCount={dashboard.organisations?.new}
                  count={dashboard.organisations?.count}
                  label={t('organisations')}
                  icon='organisations'
                />
                <Counter
                  newCount={dashboard.tutors?.new}
                  count={dashboard.tutors?.count}
                  label={t('tutors')}
                  icon='user'
                />
                <Counter
                  newCount={dashboard.sessions?.new}
                  count={dashboard.sessions?.count}
                  label={t('sessions')}
                  icon='quiz'
                />
              </div>
              <div className={styles.connection}>
                <h2>{t('connections')}</h2>
                <div className={styles.chart}>
                  <CustomLineChart
                    data={dashboard.connection.map((c : any) => ({ name: format(new Date(c.date), 'dd/MM/yyyy'), value: c.count }))}
                  />
                </div>
              </div>
            </div>
            <div className={styles.articles}>
              <h2>{t('articles')}</h2>
              <div className={styles.list}>
                {dashboard.articles.map((a: IArticle) => <ArcticleCard
                  key={a._id}
                  article={a}
                  className={'home'}
                />)}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Home;
