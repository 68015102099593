import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import styles from './quiz-table.module.scss';
import { IQuiz } from '../../types/quiz';
import Tags from '../Tags';
import { useGetListQuizQuery } from '../../services/api/quiz';

import useOptionsLang, { IOptionTranslated } from '../../hooks/useOptionsLang';

const QuizTable = (
  { quiz, handleSorting } : { quiz: any[], handleSorting: any },
) => {
  const { t } = useTranslation('quiz');
  const { data: list } = useGetListQuizQuery();

  const statusOptions = useOptionsLang(list?.status || []);
  const columns = [
    {
      header: t('columns.name'),
      accessorKey: 'name',
    }, {
      header: t('columns.lang'),
      accessorKey: 'lang',
    }, {
      header: t('columns.tags'),
      cell: (row: any) => <Tags tags={(row.getValue())}/>,
      accessorKey: 'tags',
      disableSortBy: true,
    }, {
      header: t('columns.status'),
      cell: (row: any) => {
        const status = statusOptions.find(
          (s: IOptionTranslated) => s.value === row.getValue(),
        )?.label;
        return <div className={styles.status}>
          {status}
        </div>;
      },
      accessorKey: 'status',
    },
  ];
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => quiz, [quiz]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (row: Row<IQuiz>) => {
    navigate(`/quiz/edit/${row.original._id}`);
  };

  return (
    <div className={styles.quizTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={
                    !['tags'].includes(header.id)
                      ? header.column.getToggleSortingHandler()
                      : () => {}
                  }
                >
                  {header.id !== 'tags'
                    && <>
                    {typeof header.column.getIsSorted() === 'string' ? (
                      {
                        asc: <IoChevronUpOutline />,
                        desc: <IoChevronDownOutline />,
                      }[header.column.getIsSorted() as string]
                    ) : (
                      <span className={styles.sortIcons}>
                        <IoChevronUpOutline />
                        <IoChevronDownOutline />
                      </span>
                    )}
                    </>
                  }
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}

                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuizTable;
