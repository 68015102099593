import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import styles from './mobile.module.scss';

const MobilePage = () => {
  const { t } = useTranslation('global');
  return (
    <div className={styles.mobile}>
      <img src={logo} alt="logo -tepic" />
      <p>{t('mobile')}</p>
    </div>
  );
};

export default MobilePage;
