import React, { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGetStatsGlobal } from '../../hooks/stats';
import styles from './stats.module.scss';
import Counter from '../../components/Counter';
import CustomLineChart from '../../components/LineChart';

import FilterSearchParam from '../../components/FilterSearchParam';
import { getLast12Months } from '../../utils';
import { useGetOrganisationsList } from '../../hooks/organisations';

const Stats = () => {
  const { t, i18n } = useTranslation('stats');
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);
  const { data: list } = useGetOrganisationsList();
  const { data: stats } = useGetStatsGlobal({
    ...paramsObject,
  });

  const {
    organisations = [],
    organisationRegions = [],
  } = list || {};
  const lang = i18n?.language || 'fr';

  const lastMonth = getLast12Months(lang);

  useEffect(() => {
    if (!paramsObject.date) {
      setSearchParams({ date: lastMonth[0].value });
    }
  }, [lastMonth]);

  return (
    <div className={styles.home}>
      {stats
        && <>
          <header>
            <h1>{t('global')}</h1>
            <div className={styles.filter}>
              <FilterSearchParam
                name='date'
                options={lastMonth}
                notAll={true}
              />
            </div>
          </header>
          <div className={styles.filters}>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('region')}
                name='region'
                feminine
                options={organisationRegions}
              />
            </div>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('organisation')}
                name='organisation'
                feminine
                options={organisations}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.indicators}>
              <Counter
                newCount={stats.learners?.new}
                count={stats.learners?.count}
                label={t('learners')}
                icon='user'
              />
               <Counter
                newCount={stats.organisations?.new}
                count={stats.organisations?.count}
                label={t('organisations')}
                icon='organisations'
              />
               <Counter
                newCount={stats.tutors?.new}
                count={stats.tutors?.count}
                label={t('tutors')}
                icon='user'
              />
               <Counter
                newCount={stats.sessions?.new}
                count={stats.sessions?.count}
                label={t('sessions')}
                icon='quiz'
              />
            </div>
            <div className={styles.connection}>
              <h2>{t('connections')}</h2>
              <div className={styles.chart}>
                <CustomLineChart
                  data={stats?.connectionCounts?.map((c : any) => ({ name: format(new Date(c.date), 'dd/MM/yyyy'), value: c.count }))}
                />
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Stats;
