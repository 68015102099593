import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';
import Create from './create';
import Edit from './edit';

const Quiz = () => {
  useEffect(() => {}, []);
  return (
    <Routes>
      <Route path='create' element={<Create />} />
      <Route path='edit/:id/*' element={<Edit />} />
      <Route path='*' element={<List />} />
    </Routes>
  );
};

export default Quiz;
