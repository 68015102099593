import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { resetError } from '../../../actions/auth';

import {
  InputEmail,
  ErrorField,
} from '../../../lib/HooksFormFields';

import styles from './emailresetpwd-form.module.scss';

interface IResetPasswordForm {
  errorMessage?: string;
  succeedMessage?: string;
  signinLink?: string;
  submit: (data: { email: string }) => void;
}

const ResetPasswordForm = ({
  errorMessage,
  succeedMessage,
  signinLink,
  submit,
}: IResetPasswordForm) => {
  // const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  // const watchEmail = watch('email');

  // useEffect(() => resetError(dispatch), []);

  // useEffect(() => {
  //   if (errorMessage) resetError(dispatch);
  // }, [watchEmail]);
  console.log(errorMessage);

  function onSubmit(data: any) {
    submit(data);
  }

  function handlePressEnter(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['emailresetpwd-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputEmail
            name="email"
            control={control}
            required
            label="Adresse email"
            className="login"
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        {(!errors.email) && errorMessage
          && (
          <p className={styles['message-error']}>
            {errorMessage}
          </p>
          )}
        {succeedMessage && (
          <p className={styles['message-succeed']}>{succeedMessage}</p>
        )}
      </div>
      <div className={styles.containerSubmit}>
        <button onClick={handleSubmit(onSubmit)}>Valider</button>
        {signinLink
        && (
        <div className={styles['container-link']}>
          <NavLink to={signinLink}>Se connecter à mon compte</NavLink>
        </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
