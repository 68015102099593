import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

// import OrganisationForm from '../../../../components/form/OrganisationForm';
// import { ErrorField } from '../../../../lib/HooksFormFields';
import { toast } from 'react-toastify';
import { isSameDay } from 'date-fns';
import styles from './edit-session.module.scss';
import {
  useCancelSession,
  useCloseSession,
  useCreateSession, useGetConnectedLearners, useGetSession, useStartSession, useUpdateSession,
} from '../../../../hooks/session';
import SessionForm from '../../../../components/form/SessionForm';

const EditSession = () => {
  const { sessionId } = useParams();
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const {
    data: session, error: errorGet,
  } = useGetSession(sessionId);

  const { data: connectedUsers } = useGetConnectedLearners({
    sessionId: session?._id,
    startAt: session?.date && isSameDay(new Date(), new Date(session.date)),
  });
  // Create
  const {
    mutateAsync: createSession,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreateSession();
  // Update
  const {
    mutateAsync: updateSession,
    error: errorPut,
  } = useUpdateSession();

  const {
    mutateAsync: startSession,
  } = useStartSession();

  const {
    mutateAsync: closeSession,
  } = useCloseSession();

  const {
    mutateAsync: cancelSession,
  } = useCancelSession();

  const formRef = useRef<any>();
  const errorApi: any = errorGet || errorPost || errorPut;
  console.log(errorApi, session?.startAt);

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;
    if (session) {
      const { examSession: updatedSession }: any = await updateSession(data);
      if (updatedSession) {
        toast.success(t('form.success', { ns: 'sessions' }));
      }
    } else {
      const { examSession: createdSession }: any = await createSession(data);
      if (createdSession) navigate('/session');
    }
  };

  return (
    <div className={styles.editSession}>
      <div className={styles.actions}>
        {connectedUsers?.length > 0 && <div className={styles.count}>{t('connectedUsers', { ns: 'sessions' })}<span>{connectedUsers?.length} / {session?.learners.length}</span></div>}
        {sessionId
          && connectedUsers?.length > 0
          && !session?.startAt && session?.date && isSameDay(new Date(), new Date(session.date))
          && <button
            className={!isSameDay(new Date(), new Date(session.date)) ? 'disabled' : ''}
            onClick={() => startSession(sessionId)}
          >
            {t('startSession', { ns: 'sessions' })}
          </button>
        }
        {sessionId
          && session?.startAt && session?.date && isSameDay(new Date(), new Date(session.date))
          && <>
              <button
              className={`${styles.cancel}`}
              onClick={() => cancelSession(sessionId)}
            >
              {t('cancelSession', { ns: 'sessions' })}
            </button>
              <button
              onClick={() => closeSession(sessionId)}
            >
              {t('closeSession', { ns: 'sessions' })}
            </button>
          </>
        }
        <button disabled={isPostLoading} onClick={handleSubmit}
        >
          {isPostLoading && <div className={styles.dotLoader} />}
          <span>{t('save', { ns: 'global' })}</span>
        </button>
      </div>

      <div className={styles.content}>
        <SessionForm ref={formRef} session={session}/>
      </div>
    </div>
  );
};

export default EditSession;
