import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './advanced-distribution.module.scss';
import QuestionsDistribution from '../QuestionsDistribution';

const AdvancedDistribution = ({
  questionsType,
  idInput,
  total,
  labels,
  inputs,
  setInputs,
  handleChangeOptions,
  options,
}:{
  questionsType: string,
  idInput: string,
  total: number,
  labels?: any,
  inputs?: any,
  setInputs?: (obj:any) => void,
  handleChangeOptions: (name: string, value: number) => void,
  options: any,
}) => {
  const { t } = useTranslation('createQuiz');
  return (
    <div className={styles.advanced}>
      <div className={styles.header}>
        <h3>{t(questionsType)}</h3>
      </div>
      <div className={styles.distribution}>
        <QuestionsDistribution
          questionsType=''
          idInput={idInput}
          totalQuestions={total}
          labels={labels}
          inputs={inputs}
          setInputs={setInputs}
        />
      </div>
      {options?.length > 0
        && <div className={styles.options}>
          {options.map((opt: any) => (
            <div key={opt.name} className={styles.option}>
              <label>{t(`advanced.${opt.name}`)}</label>
              <div className={styles.numbers}>
              {opt.options?.map((o:number) => (
                <button key={`${questionsType}${opt.name}${o}`}
                  onClick={() => handleChangeOptions(opt.name, o)}
                  className={o === opt.value ? styles.active : ''}
                >
                  <p>{o}</p>
                </button>
              ))}
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default AdvancedDistribution;
