/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  IUser,
  ArgsGetUsers,
} from '../../types/users';

const getUsers = async (params: ArgsGetUsers) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const formatedParams: any = {
    ...params,
  };
  // eslint-disable-next-line prefer-destructuring
  if (formatedParams.role?.length > 0) formatedParams.role = formatedParams.role[0];

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users`,
    {
      headers: {
        Authorization: token,
      },
      params: formatedParams,
    },
  );
  console.log(data);
  return data?.users;
};

const getUser = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.user;
};

const getUsersList = async (params?: { organisations?: string }) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/list`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.list;
};

// eslint-disable-next-line max-len
const createUser = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/users`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

// eslint-disable-next-line max-len
const updateUser = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const deleteUser = async (_id: string): Promise<any> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/users/${_id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getUsers,
  getUser,
  getUsersList,
  createUser,
  updateUser,
  deleteUser,
};
