import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';

import Detail from './detail';
import CreateFormation from './create';
import EditFormation from './edit';

const Formations = () => (
  <Routes>
    <Route path='create' element={<CreateFormation />} />
    <Route path='edit/:formationId' element={<EditFormation />} />
    <Route path='detail/:formationId/*' element={<Detail />} />
    <Route path='*' element={<List />} />
  </Routes>
);

export default Formations;
