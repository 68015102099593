import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';
import CreatePlantsList from './create';
import DetailPlantsList from './detail';

const PlantsLists = () => (
  <Routes>
    <Route path='create' element={<CreatePlantsList />} />
    <Route path='detail/:id/*' element={<DetailPlantsList />} />
    <Route path='*' element={<List />} />
  </Routes>
);

export default PlantsLists;
