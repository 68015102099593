/* eslint-disable import/no-named-as-default */
import React from 'react';
import {
  Routes, Route, NavLink, useParams, useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';

import styles from './session-detail.module.scss';
import Learners from './learners';
import { useGetSession } from '../../../hooks/session';
import EditSession from './edit';

const Details = () => {
  const { t } = useTranslation(['global', 'sessions']);
  const { sessionId } = useParams();
  const { pathname } = useLocation();
  const {
    data: formation,
  } = useGetSession(sessionId);
  return (
    <div className={styles.detail}>
      <div className={styles.navigation}>
        <NavLink className={styles.backward} to="/session">
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
        <header>
          <div className={styles.title}>
            <h1>{formation?.name}</h1>
          </div>
          <div className={styles.links}>
            <NavLink to={`/session/detail/${sessionId}`}
              className={`${!pathname.includes('/users') ? styles.active : ''}`}
            >
              {t('nav.informations', { ns: 'sessions' })}
            </NavLink>
            <NavLink
              to={`/session/detail/${sessionId}/users`}
              className={`${pathname.includes('/users') ? styles.active : ''}`}
            >
            {t('nav.members', { ns: 'sessions' })}
            </NavLink>
          </div>
        </header>
      </div>
      <Routes>
        <Route path='/users' element={<Learners />} />
        <Route path='*' element={<EditSession />} />
      </Routes>
    </div>
  );
};

export default Details;
