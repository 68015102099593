import React, { useCallback, useMemo } from 'react';
import { Control, useForm, useController } from 'react-hook-form';
import {
  StyledSelect,
} from '../../lib/HooksFormFields';
import Tag from '../Tag';
import { IOption } from '../../types/options';
import styles from './select-multiple.module.scss';

interface SelectMultipleProps {
  label?: string,
  name: string;
  control: Control<any>;
  options: IOption[],
  isIcon?: boolean,
  inputMaxSize?: string,
}

const SelectMultiple = ({
  label,
  name,
  control,
  options,
  inputMaxSize = '250px',
}: SelectMultipleProps) => {
  const { control: selectControl, setValue, reset } = useForm();

  const { field: { onChange, value: selections } } = useController({
    control,
    name,
  });

  const addItem = (option: string) => {
    if (option) {
      const arrayValues = selections || [];
      onChange([...arrayValues, option]);
      reset();
    }
    setValue(name, null);
  };

  const deleteItem = (string: string) => {
    const arrayValues = (selections || []).filter((str: string) => str !== string);
    onChange(arrayValues);
  };

  const getText = useCallback((str: string) => (
    (options || []).find((opt: IOption) => opt.value === str)?.label
  ), [options]);

  const getColor = useCallback((str: string) => (
    (options || []).find((opt: IOption) => opt.value === str)?.color
  ), [options]);

  const filteredOptions = useMemo(() => (
    options.filter((opt: IOption) => (!selections || !selections.includes(opt.value)))
  ), [options, selections]);

  return (
    <div className={styles.selectMultiple}>
      <h4>{label}</h4>
      <div
        className={`${styles.containerField} ${styles.type}`}
        style={{ maxWidth: inputMaxSize }}
      >
        <StyledSelect
          name={'select'}
          control={selectControl}
          options={filteredOptions}
          handleChange={(opt) => addItem(opt.value)}
          isClearable
        />
      </div>
      {(selections || []).length > 0 && (
        <div className={styles.tags}>
          {selections.map((str: string, index: number) => (
            <Tag
              key={`tag-${index}`}
              text={getText(str) || ''}
              color={getColor(str)}
              handleDelete={() => deleteItem(str)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectMultiple;
