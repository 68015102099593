import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: [
    'Users',
    'User',
    'UsersList',
    'Quiz',
    'UserCategories',
    'UserCategoriesList',
    'Organisations',
    'Organisation',
    'OrganisationsList',
    'Centers',
    'Center',
    'CentersList',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export const apiUtil = apiSlice.util;
export default apiSlice;
