/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ArgsGetArticles, IArticle } from '../../types/article';

const getArticles = async (params: ArgsGetArticles) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/articles`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.articles;
};

const getArticlesList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/articles/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const getArticleById = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/articles/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.article;
};

const createArticle = async (data: Partial<IArticle>)
: Promise<{ plantsList: IArticle }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/articles`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const updateArticle = async (data: Partial<IArticle>)
: Promise<{ article: IArticle }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/articles/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const deleteArticle = async (_id: string): Promise<any> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/articles/${_id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getArticles,
  getArticleById,
  createArticle,
  updateArticle,
  deleteArticle,
  getArticlesList,
};
