import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useUpdateProfileMutation } from '../../services/api/auth';
import { setProfile } from '../../stores/slices/authSlice';
import {
  InputText,
  InputFormatPattern,
  ErrorField,
} from '../../lib/HooksFormFields';
import styles from './account.module.scss';

const Account = () => {
  const { t } = useTranslation(['global', 'account']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector((state: any) => state.authReducer);
  const { user } = authReducer;
  const [updateProfile, isLoading] = useUpdateProfileMutation();
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: user,
  });
  const errorsForm: any = errors;

  const submit = async (data: any) => {
    try {
      const { profile, phone } = data;
      const updatedUser = await updateProfile({
        profile,
        phone,
      }).unwrap();
      if (updatedUser) {
        dispatch(setProfile({ user: updatedUser }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.account}>
      <div className={styles.header}>
        <a className={styles.backward} onClick={() => navigate(-1)}>
          <FaArrowLeft />
        </a>
        <div>
          <p className={styles.createdAt}>
            {t('createdAt', { ns: 'createUser' })} {`${format(new Date(user.createdAt), 'dd / MM / yy', { locale: fr })}`}
          </p>
        <button onClick={handleSubmit(submit)}>
          {(isLoading) && <div className={styles.dotLoader} />}
          <span>{t('save')}</span>
        </button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <div className={styles.row}>
            <div className={styles.containerField}>
              <InputText
                name='profile.firstName'
                label={t('fields.firstName', { ns: 'account' })}
                control={control}
                rules={{
                  required: t('requiredField', { ns: 'global' }),
                }}
              />
              {errorsForm?.profile?.firstName && (
                <ErrorField message={errorsForm?.profile.firstName.message} />
              )}
            </div>
            <div className={styles.containerField}>
              <InputText
                name='profile.lastName'
                label={t('fields.lastName', { ns: 'account' })}
                control={control}
                rules={{
                  required: t('requiredField', { ns: 'global' }),
                }}
              />
              {errorsForm?.profile?.lastName && (
                <ErrorField message={errorsForm?.profile?.lastName.message} />
              )}
            </div>
          </div>
          <div className={styles.row}>
          <div className={styles.containerField}>
              <InputFormatPattern
                name="phone"
                control={control}
                label={t('fields.phone', { ns: 'account' })}
                format="## ## ## ## ##"
                rules={{
                  pattern: {
                    value: /([0-9]{2,2}\s){4,5}([0-9]{2,2})/,
                    message: t('required.formatPhone', { ns: 'account' }),
                  },
                }}
              />
              {errorsForm?.phone && (
                <ErrorField message={errorsForm?.phone.message} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
