import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';
import CreateOrganisation from './create';
import Detail from './detail';

const Organisations = () => (
  <Routes>
    <Route path='create' element={<CreateOrganisation />} />
    <Route path='detail/:organisationId/*' element={<Detail />} />
    <Route path='*' element={<List />} />
  </Routes>
);

export default Organisations;
