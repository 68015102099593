import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useGetQuizAllQuery, useGetListQuizQuery } from '../../../services/api/quiz';
import InputSearch from '../../../components/InputSearch';
import QuizTable from '../../../components/QuizTable';
import ButtonExpandable from '../../../components/ButtonExpandable';

import styles from './list.module.scss';
import FilterSearchParam from '../../../components/FilterSearchParam';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import useOptionsLang from '../../../hooks/useOptionsLang';

const List = () => {
  const { t } = useTranslation('quiz');

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    search = '', order = 'desc', sortKey = 'name', tag = '', status = '',
  } = Object.fromEntries(searchParams);

  const debouncedSearch = useDebounce<string>(search, 500);

  const { data: quiz = [], isLoading } = useGetQuizAllQuery({
    order,
    sort: sortKey,
    status,
    search: debouncedSearch,
    tag,
  });
  const { data: list } = useGetListQuizQuery();

  const tags = useTagsToOptionsLang(list?.tags || []);
  const statusOptions = useOptionsLang(list?.status || []);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    const key = sort.id.replace('_', '.');
    if (key !== 'tags') {
      params.set('sortKey', key);
    }
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.list}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          {quiz.length > 0 && <span>{quiz.length}</span>}
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
          />
          <NavLink to="/quiz/create">
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new-quiz')}
            />
          </NavLink>
        </div>
      </div>
      {isLoading && <p>Loading</p>}
      <div className={styles.filters}>
         <FilterSearchParam
          label={t('filters.tags')}
          name='tag'
          options={tags || []}
        />
         <FilterSearchParam
          label={t('filters.status')}
          name='status'
          options={statusOptions || []}
        />
      </div>
      {quiz.length > 0 && (
        <QuizTable
          quiz={quiz}
          handleSorting={handleSorting}
        />
      )}
    </div>
  );
};

export default List;
