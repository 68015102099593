import * as React from 'react';

const IconCenter = ({ fill = '#949494', ...props } : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill={fill}
    {...props}
  >
    <path
      fill={fill}
      d="M7 0 0 4l7 4 5.727-3.273v4.606H14V4M2.545 6.787v2.666L7 12l4.454-2.547V6.787L7 9.333 2.545 6.787Z"
    />
  </svg>
);
export default IconCenter;
