import React from 'react';
import styles from './button-expandable.module.scss';

const ButtonExpandable = (
  { icon, text } : { icon: any, text: string },
) => (
  <button className={styles.button}>
    <span className={`icon ${styles.icon}`}>
      {icon}
    </span>
    <span className={styles.text}>{text}</span>
  </button>
);

export default ButtonExpandable;
