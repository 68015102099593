import React from 'react';
import { Tooltip } from 'react-tooltip';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import styles from './distributedInput.module.scss';

interface IDistributedInputProps {
  label: string;
  name: string;
  value: number;
  onInputChange: (newValue: number) => void;
  totalQuestions: number,
}

function DistributedInput({
  label, name, value, onInputChange, totalQuestions,
}: IDistributedInputProps) {
  const { t } = useTranslation('quiz');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(e.target.value);
    if (newValue <= 0) {
      newValue = 0;
    }
    if (newValue > totalQuestions) {
      newValue = totalQuestions;
    }
    onInputChange(newValue);
  };

  const isTooltip = name === 'QRU' || name === 'QRM';

  return (
    <div className={styles.distributedInput}>
      <label htmlFor={label} data-tooltip-id={name} data-tooltip-content={isTooltip ? t(name) : ''}>
        <p>{name}{isTooltip && <BsInfoCircleFill />}</p>
        <input
          type="number"
          name={label}
          id={label}
          value={value}
          onChange={handleChange}
        />
      </label>
      {isTooltip && <Tooltip id={name} />}
    </div>
  );
}

export default DistributedInput;
