import React, { useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';

import styles from './editArticle.module.scss';

import { ErrorField } from '../../../lib/HooksFormFields';
import { useDeleteArticle, useGetArticleById, useUpdateArticle } from '../../../hooks/articles';
import ArticleForm from '../../../components/form/ArticleForm';
import withModalConfirm from '../../../lib/withModalConfirm';

const EditArticle = ({ confirm }: { confirm: any }) => {
  const { t } = useTranslation(['global']);
  const { id: articleId } = useParams();
  const navigate = useNavigate();

  const {
    data: article, isLoading: isGetLoading, error: errorGet,
  } = useGetArticleById(articleId);

  const {
    mutateAsync: update,
    isLoading: isPutLoading,
    error: errorPut,
  } = useUpdateArticle();

  const { mutateAsync: deleteArticle, error: errorDelete } = useDeleteArticle();

  console.log(errorDelete);

  const formArticleRef = useRef<any>();
  const errorApi: any = errorGet || errorPut;

  const handleSubmit = async () => {
    const values = await formArticleRef.current.submit();

    if (!values) return;
    const data = { ...values };
    if (data?.publicationDate) {
      data.publicationDate = new Date(data.publicationDate).toISOString();
    }
    if (article) {
      const { article: updatedArticle }: any = await update(data);
      if (updatedArticle) {
        toast.success(t('form.success', { ns: 'articles' }));
      }
    }
  };

  const handleDeleteArticle = useCallback(async () => {
    if (!article) return;

    confirm(
      t('confirmDelete', { ns: 'articles' }),
      async () => {
        const res: any = await deleteArticle(article._id);
        if (res) {
          navigate('/actus');
        }
      },
    );
  }, [article]);

  return (
    <div className={styles.create}>
      <div className={styles.header}>
        <NavLink className={styles.backward} to='/actus'>
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
      </div>
      <div className={styles.edit}>
        <div className={styles.content}>
          {article && isGetLoading && <p>Loading</p>}
          {((articleId && article) || !articleId) && (
            <ArticleForm
              ref={formArticleRef}
              article={article}
              onSubmit={handleSubmit}
              updateArticle={update}
              submitIsLoading={isPutLoading}
              deleteArticle={handleDeleteArticle}
            />
          )}
          {errorApi?.message && (
            <ErrorField message={errorApi.message} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withModalConfirm(EditArticle);
