import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';
import CreateCenter from './create';
import DetailCenter from './detail';

const Centers = () => (
  <Routes>
    <Route path='create' element={<CreateCenter />} />
    <Route path='detail/:id/*' element={<DetailCenter/>} />
    <Route path='*' element={<List />} />
  </Routes>
);

export default Centers;
