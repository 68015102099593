import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type ITag = {
  _id: string,
  color: string,
  name: {
    fr: string,
    en: string,
  },
};

export type ITagTranslated = {
  value: string,
  label: string,
  color: string,
};

const useTagsToOptionsLang = (tags: ITag[], defaultLang = 'fr'): ITagTranslated[] => {
  const [params] = useSearchParams();
  const lang = (params.get('lang') || defaultLang) as 'fr' | 'en';

  const translatedTags = useMemo(() => (
    tags.map((tag) => ({ label: tag.name[lang], value: tag._id, color: tag.color }))
  ), [params, tags]);

  return translatedTags;
};

export default useTagsToOptionsLang;
