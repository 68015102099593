import React, { useRef } from 'react';
// import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetCenter,
  useCreateCenter,
  useUpdateCenter,
} from '../../../hooks/centers';
import { ErrorField } from '../../../lib/HooksFormFields';
import CenterForm from '../../../components/form/CenterForm';
import styles from './edit-center.module.scss';

const EditCenter = () => {
  const { id: centerId } = useParams();
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const {
    data: center, isLoading: isGetLoading, error: errorGet,
  } = useGetCenter(centerId);
  // Create
  const {
    mutateAsync: createCenter,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreateCenter();
  // Update
  const {
    mutateAsync: updateCenter,
    isLoading: isPutLoading,
    error: errorPut,
  } = useUpdateCenter();

  const formCenterRef = useRef<any>();
  const errorApi: any = errorGet || errorPost || errorPut;

  const handleSubmit = async () => {
    const data = await formCenterRef.current.submit();
    if (!data) return;
    if (center) {
      const { examCenter: updatedExamCenter }: any = await updateCenter(data);
      console.log(updatedExamCenter);
      if (updatedExamCenter) navigate('/centers');
    } else {
      const { examCenter: newExamCenter }: any = await createCenter(data);
      console.log(newExamCenter);
      if (newExamCenter) navigate('/centers');
    }
  };

  return (
    <div className={styles.createCenter}>
      <button
        type="button"
        className={styles.submit}
        onClick={handleSubmit}>
        {(isPostLoading || isPutLoading) && <div className={styles.dotLoader} />}
        <span>{t('save')}</span>
      </button>
      <div className={styles.content}>
        {centerId && isGetLoading && <p>Loading</p>}
        {((centerId && center) || !centerId) && (
          <CenterForm
            ref={formCenterRef}
            center={center}
          />
        )}
        {errorApi?.message && (
          <ErrorField message={errorApi.message} />
        )}
      </div>
    </div>
  );
};

export default EditCenter;
