import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

import {
  InputPassword,
  ErrorField,
} from '../../../lib/HooksFormFields';

import styles from './resetpwd-form.module.scss';

interface IResetPasswordForm {
  errorMessage?: string;
  succeedMessage?: string | null;
  signinLink?: string | null;
  submit: (data: { password: string }) => void;
}

const ResetPwdForm = ({
  errorMessage,
  signinLink,
  succeedMessage,
  submit,
}: IResetPasswordForm) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  function onSubmit(data: any) {
    submit(data);
  }

  function handlePressEnter(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['resetpwd-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Nouveau mot de passe"
            className="login"
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
        <div className={styles['container-field']}>
          <InputPassword
            name="confirm-password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Confirmation du mot de passe"
            className="login"
          />
          {errors['confirm-password'] && <ErrorField message={errors['confirm-password'].message} />}
        </div>
        {(signinLink && succeedMessage) ? (
          <div className={styles.succeed}>
            <NavLink to={signinLink}>
              <button
                className={`secondary ${styles.btnSucceed}`}
              >
                Se connecter à mon compte
              </button>
            </NavLink>
            <p className={styles['message-succeed']}>{succeedMessage}</p>
          </div>
        ) : (
          <div className={styles.submit}>
            <button onClick={handleSubmit(onSubmit)}>Valider</button>
            {(!errors.email && !errors.password) && errorMessage
              && (
              <p className={styles['message-error']}>
                {errorMessage}
              </p>
              )}
          </div>
        )}
      </div>

    </div>
  );
};

export default ResetPwdForm;
