/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  getUser,
  getUsers,
  getUsersList,
  createUser,
  updateUser,
  deleteUser,
} from '../../api/users';
import {
  IUser,
  ArgsGetUsers,
} from '../../types/users';

const useGetUsers = (params: ArgsGetUsers) => useQuery({
  queryKey: ['Users', params],
  queryFn: async (): Promise<IUser[]> => getUsers(params),
  keepPreviousData: true,
});

const useGetUser = (id?: string) => useQuery({
  queryKey: ['User', id],
  queryFn: async (): Promise<IUser | null | undefined> => getUser(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useGetUsersList = (params?: { organisations?: string }) => useQuery({
  queryKey: ['UsersList', params],
  queryFn: async (): Promise<any | null> => getUsersList(params),
  keepPreviousData: true,
});

const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IUser>) => createUser(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Users'] });
    },
  });
};

const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IUser>) => updateUser(data),
    onSuccess: async ({ user }) => {
      queryClient.invalidateQueries({ queryKey: ['Users'] });
      queryClient.setQueryData(['User', user._id], user);
    },
  });
};

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteUser(id),
    onSuccess: async (_: any, variables: any) => {
      queryClient.invalidateQueries({ queryKey: ['Users'] });
      queryClient.setQueryData(['User', variables], null);
    },
  });
};

export {
  useGetUsers,
  useGetUser,
  useGetUsersList,
  useCreateUser,
  useUpdateUser,
  useDeleteUser,
};
