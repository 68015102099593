import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from './list';

import Detail from './detail';
import CreateSession from './create';
import EditSession from './edit';

const Formations = () => (
  <Routes>
    <Route path='create' element={<CreateSession />} />
    <Route path='edit/:sessionId' element={<EditSession />} />
    <Route path='detail/:sessionId/*' element={<Detail />} />
    <Route path='*' element={<List />} />
  </Routes>
);

export default Formations;
