import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import TepikTeam from './tepik-team';
import CreateAdmin from './create-admin';
import Edit from './edit';

const Users = () => {
  useEffect(() => () => {
  }, []);

  return (
    <Routes>
      <Route path='create-admin' element={<CreateAdmin />} />
      <Route path='edit/:id' element={<Edit />} />
      <Route path='*' element={<TepikTeam />} />
    </Routes>
  );
};

export default Users;
