import apiSlice from '..';
import { IQuestion } from '../../../types/questions';
import { IQuiz } from '../../../types/quiz';

export type ArgsGetQuiz = {
  lang?: string[],
  type?: string[],
  sort?: string,
  order?: string,
  page?: number,
  search?: string,
  tag?: string,
  status?: string
};

export type ArgsPostQuiz = {
  name: string,
  description: string,
  status: string,
  typePlantName: string,
  settings: {
    recognitionFilters: Record<string, string>,
    knowledgeFilters: Record<string, string>,
    recognition: {
      QRU: number,
      QRM: number,
      QRA: number,
    },
    knowledgeCount: number,
    knowledge: {
      QRU: number,
      QRM: number,
      QRA: number,
    },
    recognitionCount: number,
  },
};

export type ListReturnType = {
  morphological_group: string[],
  taxonomical_group: string[],
  conditions_of_use: string[],
};

type ReturnType = {
  quiz: IQuiz[],
  meta: any,
};

const quizApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuizAll: builder.query<IQuiz[], Partial<ArgsGetQuiz>>({
      query: (arg: Partial<ArgsGetQuiz>) => {
        const {
          search,
          sort: sortKey,
          order,
          lang,
          tag,
          status,
        } = arg;
        return {
          url: '/quiz',
          params: {
            search,
            sortKey,
            order,
            lang,
            tag,
            status,
          },
        };
      },
      providesTags: [{ type: 'Quiz', id: 'LIST' }],
      transformResponse: (response: ReturnType) => response.quiz,
    }),
    getQuiz: builder.query<IQuiz, string>({
      query: (id: string) => `/quiz/${id}`,
      providesTags: (result, error, id) => [{ type: 'Quiz', id }],
      transformResponse: (response: { quiz: IQuiz }) => response.quiz,
      transformErrorResponse: (
        response: any,
      ) => ({
        ...response,
        message: response.data.error,
      }),
    }),
    getListQuiz: builder.query<any, void>({
      query: () => '/quiz/list',
      providesTags: () => [{ type: 'Quiz', id: 'LIST_OPTIONS' }],
      transformResponse: (response: { list: ListReturnType }) => response.list,
      transformErrorResponse: (
        response: any,
      ) => ({
        ...response,
        message: response.data.error,
      }),
    }),
    createQuiz: builder.mutation<IQuiz, ArgsPostQuiz>({
      query(body) {
        return {
          url: '/quiz',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          const newQuiz: IQuiz = response.data;
          // Upsert entry with the new quiz
          dispatch(
            quizApi.util.upsertQueryData('getQuiz', newQuiz._id, newQuiz),
          );
        } catch (err: any) {
          console.log(err);
        }
      },
      transformResponse: (response: any) => response.quiz,
      transformErrorResponse: (
        response: any,
      ) => ({
        ...response,
        message: response.data.error,
      }),
      invalidatesTags: () => ([
        { type: 'Quiz', id: 'LIST' },
      ]),
    }),
    updateQuiz: builder.mutation<void, Pick<IQuiz, '_id'> & Partial<IQuiz>>({
      query: ({ _id, ...patch }) => ({
        url: `/quiz/${_id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ _id }, { dispatch, queryFulfilled }) {
        // Pessimistic update
        try {
          const { data: updatedQuiz } = await queryFulfilled;
          dispatch(
            quizApi.util.updateQueryData('getQuiz', _id, (draft: any) => {
              Object.assign(draft, updatedQuiz);
            }),
          );
        } catch (err: any) {
          console.log(err);
        }
      },
      transformResponse: (response: any) => response.quiz,
      invalidatesTags: () => [{ type: 'Quiz', id: 'LIST' }],
    }),
    regenerateQuestionQuiz: builder.mutation<IQuiz, Pick<IQuestion, '_id'> & Partial<IQuiz>>({
      query: ({ _id, ...patch }) => ({
        url: `/questions/regenerate/${_id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // Pessimistic update
        try {
          const { data: updatedQuiz } = await queryFulfilled;
          dispatch(
            quizApi.util.updateQueryData('getQuiz', updatedQuiz._id, (draft: any) => {
              console.log('getQuiz', updatedQuiz);
              Object.assign(draft, updatedQuiz);
            }),
          );
        } catch (err: any) {
          console.log(err);
        }
      },
      transformResponse: (response: any) => response.quiz,
    }),
    changeQuestionPicture: builder.mutation<IQuiz, any>({
      query: ({ _id, ...patch }) => ({
        url: `/questions/change-picture/${_id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // Pessimistic update
        try {
          const { data: updatedQuiz } = await queryFulfilled;
          dispatch(
            quizApi.util.updateQueryData('getQuiz', updatedQuiz._id, (draft: any) => {
              console.log('getQuiz', updatedQuiz);
              Object.assign(draft, updatedQuiz);
            }),
          );
        } catch (err: any) {
          console.log(err);
        }
      },
      transformResponse: (response: any) => response.quiz,
    }),
    deleteQuiz: builder.mutation<{ success: boolean; _id: string }, string>({
      query(_id: string) {
        return ({
          url: `/quiz/${_id}`,
          method: 'DELETE',
        });
      },
      transformErrorResponse: (
        response: any,
      ) => ({
        ...response,
        message: 'Une erreur est survenue',
      }),
      invalidatesTags: () => [{ type: 'Quiz', id: 'LIST' }],
    }),
  }),
});

const {
  useGetQuizAllQuery,
  useGetQuizQuery,
  useGetListQuizQuery,
  useCreateQuizMutation,
  useUpdateQuizMutation,
  useRegenerateQuestionQuizMutation,
  useChangeQuestionPictureMutation,
  useDeleteQuizMutation,
} = quizApi;

export {
  useGetQuizQuery,
  useGetQuizAllQuery,
  useGetListQuizQuery,
  useCreateQuizMutation,
  useUpdateQuizMutation,
  useRegenerateQuestionQuizMutation,
  useChangeQuestionPictureMutation,
  useDeleteQuizMutation,
};
