/* eslint-disable import/no-named-as-default */
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import { useParams, useSearchParams } from 'react-router-dom';
import ButtonExpandable from '../../../../components/ButtonExpandable';
import FilterSearchParam from '../../../../components/FilterSearchParam';
import FormationsQuizModal from '../../../../components/FormationsQuizModal';

import { useGetFormation, useGetFormationsList, useUpdateFormation } from '../../../../hooks/formations';

import useTagsToOptionsLang from '../../../../hooks/useTagsToOptionsLang';
import Modal, { ModalRefProps } from '../../../../lib/Modal';
import { ITag } from '../../../../types/tags';
import styles from './QuizList.module.scss';
import { FormationQuiz } from '../../../../types/formations';
import FormationsQuizList from '../../../../components/FormationsQuizList';

const QuizList = () => {
  const [searchParams] = useSearchParams();
  const { formationId } = useParams();
  const addUserRef = useRef<ModalRefProps | null>(null);
  const { t } = useTranslation(['global', 'formations']);
  // const navigate = useNavigate();
  const {
    data: formation,
  } = useGetFormation(formationId);

  const {
    data: lists,
  } = useGetFormationsList();

  const {
    mutateAsync: updateFormation,
  } = useUpdateFormation();

  const tags = useTagsToOptionsLang(lists?.tags || []);

  const quiz = formation?.quiz || [];

  const tag = searchParams.get('tag');

  const quizFiltered = useMemo(() => quiz.filter((u: FormationQuiz) => {
    if (tag && !(u.quiz.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    return u;
  }), [quiz, tag]);

  return (
    <div className={styles.quiz}>
      <div className={styles.header}>
        <div className={styles.filters}>
          <FilterSearchParam
            label={t('filters.tags', { ns: 'formations' })}
            name='tag'
            options={tags || []}
          />
        </div>
        <div
          onClick={() => addUserRef?.current?.open()}
        >
          <ButtonExpandable
            icon={<IoAdd size={24} />}
            text={t('quiz.add', { ns: 'formations' })}
          />
        </div>
      </div>
      <FormationsQuizList
        quiz={quizFiltered}
        updateFormationOrder={(quizList: any[]) => updateFormation({
          _id: formation?._id,
          quiz: quizList,
        })}
      />
      <Modal
        ref={addUserRef}
      >
        <FormationsQuizModal
          close={() => addUserRef?.current?.close()}
        />
      </Modal>
    </div>
  );
};

export default QuizList;
