import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import { IoAdd } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';

import Tags from '../Tags';
import styles from './SessionLearnersModale.module.scss';

import useTagsToOptionsLang from '../../hooks/useTagsToOptionsLang';
import { SessionLearner } from '../../types/sessions';
import { ITag } from '../../types/tags';
import { IUser } from '../../types/users';
import FilterSearchParam from '../FilterSearchParam';
import { IOrganisation } from '../../types/organisations';

export default function SessionLearnersModale({
  close,
  update,
  learners,
  list,
}:{
  update:(obj: any) => void,
  close: () => void,
  list: any,
  learners: SessionLearner[];
}) {
  const { t } = useTranslation('sessions');

  const [searchParams, setSearchParams] = useSearchParams();

  const users = list?.learners || [];
  const tags = useTagsToOptionsLang(list?.tags || []);
  const organisationsList = list?.organisations.map(
    (o: IOrganisation) => ({ value: o._id, label: o.name }),
  ) || [];
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const organisation = searchParams.get('user.organisation');
  const tag = searchParams.get('user.tag');

  const usersFiltered = useMemo(() => users.filter((u: IUser) => {
    if (learners.find((l: SessionLearner) => l.user._id === u._id)) {
      return null;
    }
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    if (organisation && !(u.organisations as any)?.find(
      (o: IOrganisation) => o._id === organisation,
    )) {
      return null;
    }
    return u;
  }), [users, organisation, tag]);

  function handleSelectUser(userId: string) {
    let usersSelected : string[] = [...selectedUsers];
    if (usersSelected.find((u: string) => u === userId)) {
      usersSelected = usersSelected.filter((u: string) => u !== userId);
    } else {
      usersSelected.push(userId);
    }
    setSelectedUsers(usersSelected);
  }

  async function handleAddLearners() {
    const learnersUpdated : SessionLearner[] = [...learners].map(
      (u: SessionLearner) => ({ user: u.user._id, userId: u.userId }),
    );
    selectedUsers.forEach((userId: string) => {
      learnersUpdated.push({
        user: userId,
      });
    });
    await update({
      learners: learnersUpdated,
    });
    await close();
    searchParams.delete('user.group');
    searchParams.delete('user.tag');
    setSearchParams(searchParams);
  }
  return (
    <div className={styles.container}>
      <header>
        <h2>{t('users.modal.title', { ns: 'sessions' })}</h2>
      </header>
      <div className={styles.filters}>
        <FilterSearchParam
          label={t('filters.organisations', { ns: 'sessions' })}
          name='user.organisation'
          options={organisationsList}
        />
        <FilterSearchParam
          label={t('filters.tags', { ns: 'sessions' })}
          name='user.tag'
          options={tags || []}
        />
      </div>
      <div className={styles.row}>

      </div>
      <div className={`${styles.row} ${styles.label}`}>
        <div className={styles.col}>
        </div>
        <div className={styles.col}>
          <p>{t('columns.learner')}</p>
        </div>
        <div className={styles.col}>
          <p>{t('filters.tags', { ns: 'sessions' })}</p>
        </div>
      </div>
      <div className={styles.list}>
        {usersFiltered.map((u: IUser) => (
          <div key={u._id} className={styles.row}>
            <div className={styles.col}>
              <div className={styles.check}
                onClick={() => handleSelectUser(u._id)}
              >
                {selectedUsers.find((s: string) => s === u._id) && <FaCheck />}
              </div>
            </div>
            <div className={styles.col}>
              <p>{u.profile.firstName} {u.profile.lastName}</p>
            </div>
            <div className={styles.col}>
              <Tags tags={u.tags}/>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.action}>
        <button
          type="button"
          className={`${styles.btn} ${styles.add}`}
          onClick={() => handleAddLearners()}
        >
          <IoAdd />
          {t('users.modal.add', { ns: 'sessions' })}
        </button>
      </div>
    </div>
  );
}
