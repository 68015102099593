/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IoChevronUp, IoTrash } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import styles from './formationsQuizList.module.scss';
import { FormationQuiz } from '../../types/formations';
import Tags from '../Tags';

const ItemType = 'ROW';

const Row = ({
  quiz,
  index,
  moveRow,
  updateFormationOrder,
  updateSuccessCondition,
  deleteQuiz,
}: {
  quiz: FormationQuiz,
  index: number,
  moveRow: any
  updateFormationOrder: () => void,
  updateSuccessCondition: (index: number, condition: number) => void
  deleteQuiz: (id: string) => void,
}) => {
  const [successCondition, setSuccessCondition] = useState(quiz?.successCondition);
  const debouncedCondition = useDebounce<number>(successCondition, 500);
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      updateFormationOrder();
    },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item: { index: number }) => {
      if (item.index !== index) {
        moveRow(item.index, index);
        item.index = index;
      }
    },
    collect: (monitor) => ({
      hoveredIndex: monitor.getItem()?.index,
    }),
  });

  drag(drop(ref));

  useEffect(() => {
    if (debouncedCondition !== quiz.successCondition) {
      updateSuccessCondition(index, debouncedCondition);
    }
  }, [debouncedCondition]);

  return (<div
      ref={ref}
      className={`${styles.row} ${isDragging ? styles.dragging : ''}`}
    >
    <div className={styles.col}>
      <p className={styles.order}>{index + 1}</p>
    </div>
    <div className={styles.col}>
      <p>{quiz?.quiz?.name}</p>
    </div>
    <div className={styles.col}>
      <div className={styles.condition}>
        <input
          type="number"
          min='0'
          max={`${quiz.quiz.questions.length}`}
          value={successCondition}
          onChange={(e: any) => setSuccessCondition(parseFloat(e.target.value))}
        />
       <span>/</span><p>{quiz.quiz.questions.length}</p>
      </div>
    </div>
    <div className={styles.col}>
      <Tags tags={quiz?.quiz.tags}/>
    </div>
    <div className={styles.col}>
      <div
        onClick={() => deleteQuiz(quiz?.quiz?._id)}
        className={styles.delete}
      >
        <IoTrash />
      </div>
    </div>
  </div>);
};

const FormationsQuizList = ({
  quiz,
  updateFormationOrder,
}: {
  quiz: FormationQuiz[],
  updateFormationOrder: (list: any[]) => void,
}) => {
  const { t } = useTranslation('formations');
  const [searchParams, setSearchParams] = useSearchParams();
  const { order = 'desc', sortKey = '' } = Object.fromEntries(searchParams);
  const [quizList, setQuizList] = useState<FormationQuiz[]>([]);

  const handleSorting = (key: any) => {
    if (!key) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', key);
    params.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const updatedQuizList : FormationQuiz[] = [...quizList];
    const [movedItem] = updatedQuizList.splice(dragIndex, 1);
    updatedQuizList.splice(hoverIndex, 0, movedItem);
    const reorderedQuizList : FormationQuiz[] = updatedQuizList.map(
      (q: FormationQuiz, i: number) => ({ ...q, index: i }),
    );
    setQuizList(reorderedQuizList);
  };

  const updateSuccessCondition = (index: number, condition: number) => {
    const updatedQuizList : any[] = [...quizList];
    updatedQuizList[index].successCondition = condition;
    setQuizList(updatedQuizList);
    updateFormationOrder(updatedQuizList.map(
      (a:FormationQuiz) => ({ quiz: a.quiz._id, successCondition: a.successCondition }),
    ));
  };

  const deleteQuiz = async (id: string) => {
    const updatedQuizList : any[] = quizList.filter((q) => q.quiz?._id !== id);
    await updateFormationOrder(updatedQuizList.map(
      (a:FormationQuiz) => ({ quiz: a.quiz._id, successCondition: a.successCondition }),
    ));
  };

  const labels = [
    {
      label: t('columns.order'),
    },
    {
      label: t('columns.quizName'),
    },
    {
      label: t('columns.condition'),
    },
    {
      label: t('columns.tags'),
    },
    {
    },
  ];

  useEffect(() => {
    if (quiz) {
      setQuizList(quiz.map((q: FormationQuiz, i:number) => ({ ...q, index: i })));
    }
  }, [quiz]);

  console.log(quiz);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.content}>
        <div className={`${styles.row} ${styles.label}`}>
          {labels.map((l: any) => <div key={l.label}
            className={`${styles.col} ${l?.sortKey ? styles.sort : ''} ${order === 'desc' ? styles.desc : ''}`}
            onClick={() => handleSorting(l?.sortKey)}
          >
            {l?.sortKey === sortKey && <div className={styles.icon}>
              <IoChevronUp />
            </div>
            }
            <p>{l.label}</p>
          </div>)}
        </div>
        <div className={styles.list}
        >
          {quizList.map((q: FormationQuiz, i: number) => (<Row
            index={i}
            key={q._id}
            quiz={q}
            moveRow={moveRow}
            updateSuccessCondition={
              (index: number, condition: number) => updateSuccessCondition(index, condition)
            }
            updateFormationOrder={() => updateFormationOrder(quizList.map(
              (a:FormationQuiz) => ({ quiz: a.quiz._id, successCondition: a.successCondition }),
            ))}
            deleteQuiz={(id: string) => deleteQuiz(id)}
          />))}
        </div>
      </div>
  </DndProvider>
  );
};
export default FormationsQuizList;
