import React from 'react';
import {
  NavLink,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';

import Edit from '../edit';

import styles from './plants-list-detail.module.scss';

const Details = () => {
  const { t } = useTranslation(['global', 'plantsList']);
  return (
    <div className={styles.detail}>
      <div className={styles.navigation}>
        <NavLink className={styles.backward} to="/plants">
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
      </div>
      <Edit />
    </div>
  );
};

export default Details;
