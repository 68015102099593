import React from 'react';
import NavBar from '../NavBar';
import styles from './layout.module.scss';

const Layout = ({ children } : { children: React.ReactNode }) => (
  <>
    <NavBar />
    <div className={styles.global}>
      <div className={styles.container}>
        {children}
      </div>
    </div>
  </>
);

export default Layout;
