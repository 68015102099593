import React, { useRef } from 'react';
import {
  NavLink,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import styles from './edit.module.scss';
import { useGetTag, useUpdateTag } from '../../../hooks/tags';
import { ErrorField } from '../../../lib/HooksFormFields';
import TagForm from '../../../components/form/TagForm';

const editTags = () => {
  const params = useParams();
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const {
    mutateAsync: updateTag,
    isLoading: isPostLoading,
    error,
  } = useUpdateTag();
  const updateTagError: any = error;
  const {
    data: tag, isLoading: isGetLoading,
  } = useGetTag(params?.id);

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;
    const { tag: updatedTag }: any = await updateTag({
      _id: tag?._id,
      ...data,
    });
    if (updatedTag) navigate('/tags');
  };

  return (
    <div className={styles.editTags}>
      <div className={styles.header}>
        <NavLink className={styles.backward} to='/tags'>
          <FaArrowLeft />
        </NavLink>
        <div>
          <button disabled={isPostLoading} onClick={handleSubmit}>
            {isPostLoading && <div className={styles.dotLoader} />}
            <span>Enregistrer</span>
          </button>
        </div>

      </div>
      <div className={styles.form}>
        {isGetLoading && <p>Loading</p>}
        {tag
        && <TagForm
            ref={formRef}
            tag={tag}
        />}
        {updateTagError?.message && (
          <ErrorField message={updateTagError.message} />
        )}
      </div>
    </div>
  );
};

export default editTags;
