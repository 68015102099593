import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import { useUpdateQuizMutation } from '../../../../services/api/quiz';
import InputFile from '../../../InputFile';

import styles from './quiz-picture.module.scss';

const QuizPicture = ({ quiz }: { quiz: any }) => {
  const { t } = useTranslation('createQuiz');
  const [updateQuiz] = useUpdateQuizMutation();
  if (!quiz) {
    return null;
  }
  return (
    <div className={styles.block}>
      <h4>{t('picture.label')}</h4>
      <InputFile
        fileId={quiz?.picture?._id}
        submit={(picture: string) => updateQuiz({ _id: quiz?._id, picture }) }
        id={'profile.picture'}
      >
        {quiz?.picture?.path
          ? <>
            <div className={`${styles.picture} ${quiz?.picture?.path && styles.media}`}>
              <img src={`${process.env.REACT_APP_API_URL}/files/public/${quiz?.picture.path}`}
                className={styles.img}
              />
          </div>
        </> : <div className={`${styles.picture}`}>
          <FiDownload /><p> {t('picture.upload')}</p>
        </div>}
      </InputFile>
    </div>
  );
};

export default QuizPicture;
