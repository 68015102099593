const get = (obj = {}, path: string) => {
  const properties: any[] = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const equals = (a: any, b: any): any => {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date) { return a.getTime() === b.getTime(); }

  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) { return a === b; }

  if (a.prototype !== b.prototype) return false;

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  return keys.every((k) => equals(a[k], b[k]));
};

export {
  get,
  equals,
};

export function getLast12Months(lang: string): { value: string; label: string }[] {
  const monthNames : any = {
    fr: [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
    ],
    en: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ],
  };

  if (lang && !monthNames[lang]) {
    throw new Error(`Unsupported language: ${lang}`);
  }

  const result: { value: string; label: string }[] = [];
  const today = new Date();

  for (let i = 0; i < 13; i += 1) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    const month = monthNames[lang][date.getMonth()];
    const year = date.getFullYear();
    const value = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-01`;
    const label = `${month} ${year}`;

    result.push({ value, label });
  }

  return result;
}
