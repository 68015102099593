import React, { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetStatsFormation } from '../../hooks/stats';
import styles from './stats-formations.module.scss';
import Counter from '../../components/Counter';

import FilterSearchParam from '../../components/FilterSearchParam';
import { getLast12Months } from '../../utils';
import { useGetOrganisationsList } from '../../hooks/organisations';
import IconMembers from '../../components/NavBar/Icons/IconMembers';

const StatsFormations = () => {
  const { t, i18n } = useTranslation('stats');
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);
  const { data: list } = useGetOrganisationsList();
  const { data: stats } = useGetStatsFormation({
    ...paramsObject,
  });

  const {
    organisations = [],
    organisationRegions = [],
  } = list || {};
  const lang = i18n?.language || 'fr';

  const lastMonth = getLast12Months(lang);

  useEffect(() => {
    if (!paramsObject.date) {
      setSearchParams({ date: lastMonth[0].value });
    }
  }, [lastMonth]);

  return (
    <div className={styles.stats}>
      {stats
        && <>
          <header>
            <h1>{t('formations.title')}</h1>
            <div className={styles.filter}>
              <FilterSearchParam
                name='date'
                options={lastMonth}
                notAll={true}
              />
            </div>
          </header>
          <div className={styles.filters}>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('region')}
                name='region'
                feminine
                options={organisationRegions}
              />
            </div>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('organisation')}
                name='organisation'
                feminine
                options={organisations}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.indicators}>
              <Counter
                count={stats?.tutors}
                label={t('tutors')}
                icon='user'
              />
              <Counter
                count={stats?.learners}
                label={t('formations.learners')}
                icon='user'
              />
            </div>
            {stats?.formations?.length > 0
              && <div className={styles.formations}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <p>{t('col.name')}</p>
                  </div>
                  <div className={styles.col}>
                    <p>{t('col.learners')}</p>
                  </div>
                  <div className={styles.col}>
                    <p>{t('col.progress')}</p>
                  </div>
                </div>
                {stats.formations.map((f: any) => (
                  <div
                    key={f._id}
                    className={styles.row}>
                    <div className={styles.col}>
                      <p>{f.name}</p>
                    </div>
                    <div className={styles.col}>
                      <IconMembers />
                      <p>{f.learners}</p>
                    </div>
                    <div className={styles.col}>
                      <p>{f.progress.toFixed()}/{f.quiz}</p>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        </>
      }
    </div>
  );
};

export default StatsFormations;
