import React from 'react';
import { IoAdd } from 'react-icons/io5';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import styles from './list.module.scss';
// import {
//   useGetCentersQuery,
//   useGetCentersListQuery,
// } from '../../../services/api/centers';
import { useGetCenters, useGetCentersList } from '../../../hooks/centers';
import InputSearch from '../../../components/InputSearch';
import CentersTable from '../../../components/CentersTable';
import ButtonExpandable from '../../../components/ButtonExpandable';
import FilterSearchParam from '../../../components/FilterSearchParam';

const Centers = () => {
  const { t } = useTranslation('centers');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const debouncedSearch = useDebounce<string>(search, 500);
  const { data: list } = useGetCentersList();

  const { data, isLoading } = useGetCenters({
    ...paramsObject,
    search: debouncedSearch,
  });
  const centers = data || [];

  // const { data: centers = [], isLoading } = useGetCentersQuery({
  //   ...paramsObject,
  //   search: debouncedSearch,
  // });

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sort', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  return (
    <div className={styles.listCenters}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          {centers.length > 0 && 's'}
          <span>{centers.length}</span>
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            defaultValue={search}
          />
          <NavLink to="/centers/create">
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new-center')}
            />
          </NavLink>
        </div>
      </div>
      {isLoading && <p>Loading</p>}
      <div className={styles.filters}>
        <FilterSearchParam
          label={t('filters.region')}
          name={'region'}
          options={list?.regions || []}
          feminine
        />
      </div>
      {centers.length > 0 && (
        <CentersTable
          centers={centers}
          handleSorting={handleSorting}
        />
      )}
    </div>
  );
};

export default Centers;
