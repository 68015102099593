import React, { useImperativeHandle, forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { AsyncStyledSelect } from '../../../../lib/HooksFormFields';
import styles from './select-plant.module.scss';

type PlantProps = {
  value: string
  label: string | null
};

interface SelectPlantsRefProps {
  getPlant: () => void,
}

// eslint-disable-next-line max-len
const SelectPlants = forwardRef<SelectPlantsRefProps, { defaultPlant: PlantProps | null }>(({ defaultPlant }, ref) => {
  const { control, setValue, getValues } = useForm({
    defaultValues: {
      plant: defaultPlant,
    },
  });

  useImperativeHandle(ref, () => ({
    getPlant: () => getValues('plant'),
  }));

  return (
    <div className={styles.plants}>
      <AsyncStyledSelect
        name='plant'
        endpoint='/plants/list'
        control={control}
        handleChange={(p: PlantProps) => {
          setValue('plant', p);
        }}
      />
    </div>
  );
});

export default SelectPlants;
