import * as React from 'react';

const IconMembers = ({ fill = '#949494', ...props } : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M6.316 6.316a3.04 3.04 0 0 1-2.23-.928 3.04 3.04 0 0 1-.928-2.23c0-.869.31-1.612.928-2.23A3.04 3.04 0 0 1 6.316 0c.868 0 1.612.31 2.23.928a3.04 3.04 0 0 1 .928 2.23 3.04 3.04 0 0 1-.928 2.23 3.04 3.04 0 0 1-2.23.928ZM0 12.632v-2.21c0-.448.115-.86.346-1.235a2.3 2.3 0 0 1 .917-.858 11.736 11.736 0 0 1 2.487-.918 10.821 10.821 0 0 1 2.566-.306c.868 0 1.723.102 2.566.307.842.204 1.67.51 2.486.917.382.197.688.484.919.859.23.375.345.786.345 1.233v2.21H0Z"
    />
  </svg>
);

export default IconMembers;
